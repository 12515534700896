.float-buttons {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 24px;
  right: 24px;

  & > div {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.context-menu {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(54, 56, 57, 0.16);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:focus {
    outline: 0;
  }

  &.active {
    z-index: 11;

    & > svg {
      transform: rotate(45deg);
    }

    & .context-menu-list,
    & .buttons {
      opacity: 1;
      visibility: visible;
    }
  }

  & > svg {
    fill: $blue;
    width: 20px;
    height: 20px;
    @include transition(300ms)
  }

  &-list,
  & .buttons {
    position: absolute;
    bottom: 0;
    right: 79px;
    padding: $small-offset;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    box-shadow: 0 4px 16px rgba(54, 56, 57, 0.16);
    border-radius: 2px;
    opacity: 0;
    visibility: hidden;
    @include transition(300ms)
  }

  &-list {
    padding: 4px 0;
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 21px;
    color: #282A2B;
    white-space: nowrap;
    @include transition(300ms);
    padding: 8px 16px;

    &:last-child {
      margin-bottom: 0;
    }

    & svg {
      width: $small-offset;
      height: $small-offset;
      margin-right: $base-offset;
      fill: #282A2B;
      @include transition(300ms);
    }

    &:hover {
      color: $blue;
      background: rgba(0, 95, 206, 0.08);
      border-radius: 4px;

      & svg {
        fill: $blue;
      }
    }
  }

  & .buttons {
    padding: 4px 0;

    & .buttons-item {
      white-space: nowrap;
      background-color: transparent;
      padding: 8px 24px;
      margin-bottom: 0;
      @include transition(300ms);

      &:hover {
        background-color: rgba($blue, .16);
      }
    }
  }

  &.contract {
    bottom: 100px;


    & .contract-navigation-menu {
      padding-left: 24px;
      padding-right: 24px;
      background-color: white;
      overflow: hidden;
      min-width: 380px;
      position: absolute;
      bottom: -30px;
      right: 65px;
      z-index: 1;
      max-height: 83vh;
      overflow-y: scroll;
      border: 1px solid #EBEBEB;
      box-shadow: 0 4px 16px rgba(54, 56, 57, 0.16);
      border-radius: 2px;
    }
  }
}
