.chooses-content {
  display: flex;
  justify-content: space-between;

  & > div {
    width: 100%;
  }

  & .cards {
    width: calc(100% / 2 - 12px);
    cursor: pointer;

    &:hover {
      & .cards-ico {
        background: $blue;

        & svg {
          fill: #ffffff;
        }
      }

      & > p {
        color: $blue;
      }
    }

    &:first-child {
      margin-right: $small-offset;
    }

    & .cards-ico {
      background: rgba(0, 94, 184, 0.08);
      border-radius: 2px;
      padding: 32px;
      @include transition(300ms);

      & svg {
        fill: #005EB8;
      }
    }

    & > p {
      margin-top: 8px;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #282A2B;
    }

  }
}
