.roundButton {
    transition: .3s;
    padding: 0 12px;
    background-color: #fff;
    border-radius: 2px;
    width: 100%;
    border: 1px solid $border-color;
    font-family: $font-book;
    font-size: 14px;
    line-height: 17px;
    height: $base-offset * 2;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $border-color;

    &.ignoredBtn {
        border-color: transparent;
        color: black;

        & svg {
            fill: black;
            @include transition(300ms);
        }

        &:hover {
            border-color: transparent;
            background-color: transparent;
            color: $blue;

            & svg {
                fill: $blue;
            }
        }
    }

    & svg {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        fill: $border-color;
    }

    &:hover {
        transition: .3s;
        background: $border-color;
        color: #ffffff;

        & svg {
            fill: #ffffff;
        }
    }

    &:focus {
        outline: none;
    }

    .fa {
        font-size: 11px;
        color: $darker-grey;
        display: inline-block;
        margin-right: 3px;
    }

    &.thin {
        padding: 0 $base-offset / 2;
        font-size: 10px;
        line-height: $base-offset * 1.5;
        height: $base-offset * 1.5;
    }

    &.rect {
        border-radius: 4px;
        text-align: left;

        & .fa {
            margin-right: $base-offset / 2;
        }
    }

    &.red {
        background-color: $red;
        color: #fff;
        border-color: $red;

        &:hover {
            transition: .3s;
            background-color: darken($red, 15);
        }

        & .fa {
            color: #fff;
        }
    }

    &.orange {
        background-color: $orange;
        color: #fff;
        border-color: $orange;

        &:hover {
            transition: .3s;
            background-color: darken($orange, 10);
        }

        & .fa {
            color: #fff;
        }
    }

    &.green {
        background-color: $green;
        color: #fff;
        border-color: $green;

        .fa {
            color: #fff;
        }

        &:hover {
            transition: .3s;
            background-color: darken($green, 10);
        }
    }

    &.blue {
        background-color: $blue;
        color: #fff;
        border-color: $blue;

        .fa {
            color: #fff;
        }

        &:hover {
            transition: .3s;
            background-color: darken($blue, 10);
        }

        &:focus {
            box-shadow: $shadow-focus;
        }
    }

    &.grey {
        background-color: $grey;
        color: #fff;
        border-color: $grey;

        .fa {
            color: #fff;
        }

        &:hover {
            transition: .3s;
            background-color: darken($grey, 10);
        }
    }

    &:disabled {

        .fa {
            color: #fff !important;
        }

    }

    &.lighter-grey {
        background-color: $lighter-grey;
        color: $dark;
        border-color: $lighter-grey;

        .fa {
            color: $dark;
        }

        &:hover {
            transition: .3s;
            background-color: darken($lighter-grey, 10);
        }
    }

    &.dark {
        background-color: $dark;
        color: #fff;
        border-color: #fff;

        .fa {
            color: #fff;
        }

        &:hover {
            transition: .3s;
            background-color: lighten($dark, 10);
        }
    }

    &.transparent {
        background-color: transparent;
        color: $blue;
        border-color: $blue;

        .fa {
            color: $blue;
        }

        &:hover {
            transition: .3s;
            background-color: rgba($blue, .08);

            & svg {
                fill: $blue;
            }
        }
        &:focus {
            box-shadow: $shadow-focus;
        }
        &:active {
            background-color: rgba($blue, .16);
        }
    }

    &.loading {
        background-image: url('./../../media/green-pixel.png');
        background-color: $grey;
        color: #fff;
        border-color: $grey;

        background-position: 0;
        background-size: 0 100%;
        background-repeat: no-repeat repeat;

        .fa {
            color: #fff;
        }
    }
}
.selectButton {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    padding: 4px 30px 4px 4px;
    transition: .3s;
    margin-bottom: 8px;
    border: none;
    background-color: transparent;
    position: relative;

    border-radius: 24px;
    & > .titleWrap {
        display: flex;
        & > .name {
            font-size: 16px;
            line-height: 36px;
            padding-right: 16px;
        }
        & > div > .avatar {
            margin-right: 8px;
        }
    }
    &.checked {
        & > .check {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);

            & svg {
                fill: $blue;
            }
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
    &:hover {
        background-color: #EBF2FA;
    }
    &:focus {
        outline: 0;
    }
    &[disabled] {
        color: #808080;
        background: #EBEBEB;
        border: 1px solid #DDDDDD;
        opacity: .6;
        & > .titleWrap > div > .avatar {
            background-color: #DDDDDD!important;
        }
        & > .check {
            & svg {
                fill: #808080;
            }
        }
    }
}