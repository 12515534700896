.assign-parts-form {
  position: relative;
  $icon-size: 48px;
  width: 100%;

  & .step {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &.hidden {
      animation: hideStep .3s ease-in-out forwards;
    }

    &.shown {
      animation: showStep .3s ease-in-out forwards;
    }
  }

  & .default-step {
    text-align: center;

    & .assigned-button {
      margin-bottom: $base-offset / 2;
      padding: $base-offset;
      display: inline-block;
      box-shadow: inset 0 0 0 2px #106ba3;
      color: $blue;
      border-radius: 4px;
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      background-color: transparent;

      & .row {
        display: block;
        margin-bottom: $base-offset / 2;

        & .fa {
          display: block;
          float: left;
        }

        & .fa-microchip {
          font-size: 32px;
          line-height: 32px;
        }

        & .fa-times {
          font-size: 18px;
          line-height: 32px;
          margin: 0 $base-offset / 2;
        }

        & .count {
          font-size: 32px;
          line-height: 32px;
        }
      }

      &:hover {
        @include transition(.3s);
        background-color: #fff;
      }
    }

    & .add-button {
      display: inline;
      font-size: 12px;
      color: $darker-grey;
      background-color: transparent;
      padding: 0;
      border: 0;
      outline: none;

      & .fa {
        margin-right: $base-offset / 4;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  & .type-step, & .status-step {
    @include clear();
    // margin: 0 (-$base-offset / 2);
    & .part-type, & .part-status {
      padding: 0 $base-offset;
      width: 50%;
      text-align: center;
      float: left;
      margin-bottom: $base-offset;

      & label {
        text-align: center;
        margin-top: $base-offset / 2;
        color: $darker-grey;
        font-size: 12px;
        display: block;
        text-align: center;
      }

      &.active {
        & .icon {
          @include transition(.3s);
          background-color: #fff;
          color: $blue;
          box-shadow: 0 0 6px 0 rgba($blue, .5);
          border-color: $blue;
        }
      }
    }
  }

  & .search-step {
    & .autocomplete-input, & .Select {
      margin-bottom: $base-offset / 4;
    }

    & label {
      font-size: 10px;
      color: $darker-grey;
      padding: 0 $base-offset / 2;
      margin-bottom: $base-offset;
    }
  }

  & .realizations-step {
    @include clear();

    & .realization-item {
      padding: 0;
      width: 50%;
      text-align: center;
      margin: 0 auto $base-offset / 2;

      & label {
        text-align: center;
        margin-bottom: $base-offset / 2;
        color: $darker-grey;
        font-size: 12px;
        display: block;
        text-align: center;
      }

      & .icon {
        border-color: $blue;
        color: $blue;
      }
    }

    & .selected {
      text-align: center;
      display: block;
      font-size: 10px;
      color: $darker-grey;
      margin: 0 $base-offset $base-offset / 2;
    }
  }

  & .buttons-row {
    position: relative;
    text-align: center;
    display: block;
    width: 100%;
    @include clear();

    padding: $base-offset 0 0;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: $base-offset;
      right: $base-offset;

      height: 2px;
      border-radius: 2px;
      background-color: $grey;
    }

    & .back, & .next {
      font-size: 12px;
      display: inline-block;
      padding: $base-offset / 4 $base-offset / 2;
      border-radius: 100px;
      border: 2px solid $darker-grey;
      line-height: 14px;

      &:hover {
        text-decoration: underline;
      }
    }

    & .back {
      color: $grey;
      border: 2px solid $grey;
    }

    & .next {
      color: $blue;
      border: 2px solid $blue;
    }
  }

  & .icon {
    position: relative;
    display: inline-block;
    width: $icon-size;
    height: $icon-size;
    font-size: 24px;
    color: rgba($blue, .5);
    background-color: transparent;
    border-radius: 4px;

    border: 2px solid rgba($blue, .5);

    @include transition(.3s);
    box-shadow: 0 0 0 0 rgba($blue, .5);
    //box-shadow: inset 0 0 0 2px $blue;

    & i {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate3d(-50%, -50%, 0);
    }

    &:hover {
      background-color: #fff;
      @include transition(.3s);
    }
  }

  & .custom-grid {
    margin: 0;

    & .cell-6 {
      padding: 0;
    }
  }
}

@keyframes hideStep {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes showStep {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
