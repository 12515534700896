.tabs {
  $tab-text-height: 16px;
  height: 100%;


  &-list {
    background: #fff;
    margin: 0 0 $small-offset;
    padding: 0;
    list-style: none;
    border-bottom: 2px solid transparent;


    li {
      float: left;

      &:first-of-type {
        border-top-left-radius: 4px;
      }

      &:last-of-type {
        border-top-right-radius: 4px;
      }

      @include overflow();

      a, button {
        display: block;
        @include overflow();
        position: relative;
        color: #000000;
        @include transition(.3s);
        background: none;
        border: 0;
        padding: 8px 4px;
        line-height: $tab-text-height;
        font-size: 12px;
        z-index: 0;
        margin-right: 20px;

        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0%;
          bottom: 0;
          z-index: -2;
        }

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          position: absolute;
          left: -100%;
          bottom: 0;
          background-color: $blue;
          z-index: -1;
        }

        &:focus {
          outline: none;
        }
      }

      &.active {
        a, button {
          color: $blue;
          @include transition(.3s);
        }

        &.active-right {
          button::before, a::before {
            animation: activateTabRight ease-in-out .3s forwards;
          }
        }

        &.active-left {
          button::before, a::before {
            animation: activateTabLeft ease-in-out .3s forwards;
          }
        }
      }

      &.inactive {
        a, button {
          @include transition(.3s);
        }

        &.inactive-right {
          button::before, a::before {
            animation: deactivateTabRight ease-in-out .3s forwards;
          }
        }

        &.inactive-left {
          button::before, a::before {
            animation: deactivateTabLeft ease-in-out .3s forwards;
          }
        }
      }

      &.transparent {
        a, button {
          color: rgba($blue, .2);
          @include transition(.3s);
        }

        // &.inactive-right {
        //     button::before, a::before {animation: deactivateTabRight ease-in-out .3s forwards;}
        // }
        // &.inactive-left {
        //     button::before, a::before {animation: deactivateTabLeft ease-in-out .3s forwards;}
        // }
      }
    }
  }

  &-content {
    height: 100%;
    padding: $base-offset * 1.5;
    border: 1px solid $grey;
    border-top: none;
    border-radius: 0 0 4px 4px;

    & .grid-wrap {
      box-shadow: none;

      & .grid {
        border-top: none;
        border-radius: 0px 0px 4px 4px;
      }
    }

    & .ls-form {
      margin: 0;

      & > label {
        display: none;
      }
    }

    & .filters-form label {
      display: block;
    }
  }

  &--big {
    & .tabs-list {
      background: none;

      & li {
        & button, a {
          font-size: 16px;
        }
      }
    }

    & .tabs-content {
      padding: 0;
    }
  }
}

@keyframes deactivateTabRight {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}

@keyframes deactivateTabLeft {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

@keyframes activateTabRight {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}

@keyframes activateTabLeft {
  from {
    left: 100%;
  }
  to {
    left: 0;
  }
}
