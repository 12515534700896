.tag-list {
  & ul {
    list-style: none;
    margin: 0;
    padding: 0;

    & li {
      width: 100%;
      margin-bottom: $base-offset / 4;

      & > a {
        position: relative;
        font-size: 12px;
        line-height: 24px;
        color: $darker-grey;
        padding: 0 $base-offset * 1.5 0 $base-offset / 2;
        display: inline-block;
        background: none;
        border-radius: 4px;

        & .item-delete {
          display: none;
          font-size: 14px;
          line-height: 24px;
          color: $darker-grey;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate3d(-50%, -50%, 0);
        }

        & .item-add {
          right: 0;
        }
      }
    }
  }

  & > span {
    float: left;
    font-size: 12px;
    color: $grey;
    padding-left: $base-offset / 2;
  }

  &.editable {
    & ul {
      & li {
        & > a {
          background-color: rgba($blue, .075);

          &:hover {
            @include transition(.3s);
            background-color: rgba($blue, .2);
          }

          & .item-delete {
            display: block;
          }
        }
      }
    }
  }
}
