.accordeon {
  @include clear();
  position: relative;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  background-color: #fff;
  @include transition(.3s);

  &.hidden {
    @include overflow();
  }

  &-title {
    z-index: 2;
    position: relative;
    border-radius: 4px;
    padding: $base-offset / 2 $base-offset * 2;
    display: block;

    font-family: $font-book;
    font-size: 16px;
    color: $blue;
    background-color: #fff;
    @include transition(.3s);
    @include overflow();

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      z-index: -1;
      @include transition(.3s);
    }

    &::after {
      content: '\f0d7';
      display: block;
      position: absolute;
      top: 50%;
      right: $base-offset;
      transform: translate3d(-50%, -50%, 0);
      font-family: FontAwesome;
      font-size: 18px;
      color: $blue;
      z-index: 1;
      @include transition(.3s);
    }

    &:hover {
      color: $blue;
    }

    &.open {
      border-radius: 4px 4px 0 0;
      background-color: #fff;
      color: #fff;
      @include transition(.3s);

      &:hover {
        color: #fff;
      }

      &::after {
        color: #fff;
        @include transition(.3s);
      }

      &::before {
        @include transition(.3s);
        background-color: rgba($blue, .8);
      }
    }

    & .fa {
      margin-right: $base-offset / 2;
    }
  }

  &-content {
    padding: $base-offset * 2;
    position: absolute;
    width: 100%;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    bottom: 0;
    @include transition(.3s);
  }
}
