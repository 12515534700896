.right-panel-filters {
  $icon-size: 24px;

  & .filter-field {
    cursor: pointer;
    position: relative;
    width: 100%;
    font-family: $font-book;
    font-size: 14px;
    padding: $base-offset * .75 ($icon-size + $base-offset);
    margin-bottom: 3px;
    overflow: hidden;
    color: $dark;

    &::before {
      content: '';
      font-family: FontAwesome;
      margin-right: $base-offset;

      position: absolute;
      width: $icon-size;
      height: $icon-size;
      font-size: 12px;
      line-height: $icon-size;
      text-align: center;
      top: 50%;
      left: $base-offset / 2;
      margin-top: -$icon-size / 2;
    }

    &:hover::before {
      content: '';
      color: $green;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &.active {
      background-color: rgba($green, .1);
      border-radius: 4px;
      color: $dark;

      &::before {
        content: '';
        color: $green;
      }

      &:hover {
        &::before {
          content: '';
          color: $red;
        }
      }
    }
  }
}
