.center-form-container {
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    height: calc(100vh - 30px);
    max-height: calc(100vh - 30px);

    & .status-form {

        & .selectButton {
            padding-right: 0;
            width: auto;

            &:hover {
                background-color: transparent;
            }

            & .check {
                position: absolute;
                right: -10px;
            }
        }
    }

    &.quote-details {

        & form {
            width: 100%;
            height: 100%;
            display: flex;
        }

        & .subcontractor {
            flex-direction: column;

            & > div {
                width: 100% !important;
            }
        }
    }

    & .tile {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;

        &.opportunity-tile {

            & .tile-item {
                width: calc(100% / 2 - 12px);
            }
        }

        &.three-tile {

            & .tile-item {

                &:nth-child(2),
                &:nth-child(3) {
                    width: calc(100% / 2 - 12px);
                }
            }
        }

        &-item {
            flex: 1;
            margin-right: 24px;
            border: 1px solid #E6E6E6;
            padding: 16px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            position: relative;

            &__info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                & > p > span > span {
                    color: #808080;
                    font-family: "Whitney Book";
                }
            }

            & > div {
                display: flex;
                position: relative;
            }

            &:nth-child(1) {
                width: 100%;
                flex-basis: 100%;
            }

            &:nth-child(2) {
                cursor: pointer;
            }

            &:last-child,
            &:first-child {
                margin-right: 0;
            }


            & svg {
                min-width: 40px;
                margin-right: 10px;
                max-width: 40px;
            }

            &__info {

                & > *:not(.tile-item__info-title),
                & a {
                    font-family: $font-medium;
                    font-size: 16px;
                    line-height: 21px;
                    color: #282A2B;

                }
            }

            & a {

                & svg {
                    transform: translateY(2px);
                    width: 15px;
                    min-width: 20px;
                    height: 15px;
                    fill: $blue;
                }
            }
        }
    }

    .left-panel {
        width: 350px;
        padding: 24px;
        background-color: #FAFAFA;
        height: 100%;

        & .save {
            margin-top: 35px;
            display: flex;
            justify-content: space-between;

            & button {
                width: calc(100% / 2 - 10px);
            }
        }

        .info-block {
            display: grid;
            align-items: center;
            justify-content: center;
            padding: 15px;
            background-color: white;
            border-radius: 12px;
            margin-bottom: 15px;
            text-align: center;

            .label {
                font-size: 16px;
            }
            .value {
                font-size: 15px;
                font-weight: bold;
                padding: 8px;

            }
            .button {
                cursor: pointer;
                padding: 5px 13px;
                background-color: #EBF2FA;
                border-radius: 4px;
                min-width: 120px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 18px;
                    margin-right: 4px;
                }
            }
        }
    }

    .form-wrapper {
        flex: 1;
        height: 100%;
        box-shadow: -8px 0px 16px rgba(122, 127, 130, 0.08);
        padding: 24px 25px 70px;
        width: 50%;
        overflow-y: scroll;
        min-width: 590px;
        max-height: calc(100vh - 30px);
        border-top: 1px solid #DDDDDD;
        border-left: 1px solid #DDDDDD;
        position: relative;
        border-radius: 4px 0 0 0;
        z-index: 1;

        & .file-gallery {

            & .cell-3 {
                width: calc(100% / 2 - 8px);
                margin-right: 16px;

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }

        & .section-block {

            & .field-group {

                & .ls-textarea {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }

        & .title {
            font-size: 22px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 24px;
        }

        & .top-info {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 15px;

            .title {
                margin-bottom: 0;
            }

            & .status:first-of-type {
                margin-left: 0;
            }

            & .time {
                margin-left: auto;
                display: flex;
                font-size: 16px;
                min-width: 200px;
                font-family: Helvetica Neue,Helvetica,Arial,sans-serif;

                &.green {
                    border: 2px $green solid;
                    background: lighten($green, 40%);
                }
                &.red {
                    border: 2px $red solid;
                    background: lighten($red, 30%);
                }
                &.green, &.red {
                    padding: 8px 24px;
                    border-radius: 4px;
                    width:240px;
                }

                & svg {
                    width: 18px;
                    height: 18px;
                    margin-right: 5px;
                }

                & p {
                    margin-right: 10px;
                    white-space: nowrap;
                }

                & .timer {
                    color: #363839;
                    white-space: nowrap;
                }
            }
        }

        & input {
            font-family: $font-book;
            font-size: 16px;
            line-height: 24px;
            color: black;
            border: 1px solid transparent;

            &:hover {
                border: 1px solid rgba(0, 95, 206, 0.16);
            }

            &:focus {
                border: 1px solid $blue;
            }
        }

    }

    & .ls-form {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: $base-offset;

        & .bp3-popover-target,
        & .select {
            width: 100%;
            border-radius: 2px;

            &:focus {
                outline: none;
                //box-shadow: $shadow-focus;
            }
        }

        & label {
            min-width: 210px;
            white-space: nowrap;
            margin-right: 15px;
            max-width: 210px;
        }

        & input,
        & .select > div,
        & textarea {
            border: 1px solid rgba($blue, 0.16);

            &:hover {
                border: 1px solid rgba($blue, 1);
            }

            &:focus {
                border: 1px solid rgba($blue, 1);
                outline: none;
            }
        }

        & .select {

            & input {
                border: 1px solid transparent !important;
            }

            & input:focus {
                border: 1px solid transparent !important;
            }

        }


        & .Select-control {
            padding: 0 !important;
            height: $base-offset * 2 !important;
            width: 100% !important;
            border-radius: 2px;
        }

        & .Select-value, & .Select-placeholder {
            line-height: 18px !important;
        }

        & .pt-timepicker {
            display: block;
            text-align: center;
        }
    }

    .back {
        margin-bottom: $base-offset;
        font-size: 16px;
        line-height: 17px;
        color: #282A2B;
        display: flex;
        align-items: center;

        & svg {
            width: 20px;
            height: 20px;
        }
    }

    h2 {
        padding: $small-offset;
    }

    .center-form {
        padding: $small-offset;
    }

    .right-panel {
        width: 32%;
        border-top: 1px solid #DDDDDD;
        border-left: 1px solid #DDDDDD;
        padding: 0 0 108px;
        min-width: 250px;

        & .center-form {

            & > form {
                position: relative;

                & .expenses-drop {
                    top: 0;
                }

                & .tile-item {
                    cursor: pointer;
                    width: calc(100% / 2 - 10px);
                }
            }
        }

        &.active-contract-data {
            width: 70%;
            overflow-y: scroll;
        }

        &__title {
            font-size: 18px;
            line-height: 24px;
            color: #282A2B;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: $small-offset;
            border-bottom: 1px solid #E6E6E6;

            & p {
                margin-right: 24px;
            }
        }

        &-content {
            height: 100%;
            overflow-y: scroll;

            & .no-items {
                padding: 24px;
                height: 100%
            }

            & .attachment-list {
                background: rgba(0, 95, 206, 0.08);
                border-radius: 2px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                & p {
                    margin-bottom: 15px;
                    font-size: 18px;
                    line-height: 24px;
                    color: #7A7F82;
                }
            }
        }

        & button {
            display: flex;
            align-items: center;
            justify-content: center;

            & svg {
                margin-right: 10px;
                width: 15px;
                height: 16px;
            }
        }
    }

    .tertiaryBtn {
        @include transition(300ms);
        @include tertiaryBtn()
    }

    .secondaryBtn {
        @include transition(300ms);
        @include secondaryBtn()
    }

    & .buttons {

        &-item {
            cursor: pointer;
            background: #EBF2FA;
            border-radius: 2px;
            font-size: 16px;
            line-height: 17px;
            border: 1px solid transparent;
            margin-bottom: 8px;
            padding: 8px 16px;
            display: flex;
            position: relative;
            align-items: center;

            & svg {
                width: 18px;
                height: 18px;
                margin-right: 10px;
            }
        }

        & button {
            width: 100%;
        }
    }

    .parts-item {
        display: flex;
        font-size: 16px;
        line-height: 21px;
        color: #7A7F82;
        padding: $small-offset $base-offset;
        border-bottom: 1px solid #E6E6E6;
        position: relative;


        & > div {
            margin-bottom: 6px;
        }

        & .remove {
            position: absolute;
            top: 20px;
            right: 24px;
            width: 24px;
            height: 24px;
            transform: rotate(45deg);
            cursor: pointer;
            @include transition(300ms);

            &:hover {
                fill: $blue;
            }
        }

        &__iterate {
            min-width: 25px;
            text-align: center;
            margin-right: 16px;
            color: #282A2B;
        }

        &__name, &__serial {
            margin-bottom: 4px;
        }

        &__serial {
            color: #282A2B;
            font-weight: 500;
        }

        & b {
            font-weight: 500;
        }
    }
}

.drag {
    display: flex;
    flex-wrap: wrap;

    &-item {
        width: calc(100% / 3 - 16px);
        margin-right: 24px;
        margin-bottom: 24px;
        border: 1px solid #E6E6E6;
        border-radius: 2px;
        padding: $base-offset;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: #ffffff;
        user-select: none;
        @include transition(300ms);

        &:nth-child(3) {
            margin-right: 0;
        }

        &:hover {
            border-color: $blue;
        }


        & .ico {
            width: 55px;
            height: 55px;
            border: 1px solid transparent;
            margin-right: $base-offset;
            border-radius: 2px;
        }

        & .draggable-ico {
            background-color: rgba($blue, .14);
            width: 55px;
            height: 55px;
            display: block;
            padding: 10px;
            border-radius: 2px;
            cursor: grab;
            @include transition(300ms);

            &:active {
                background-color: $blue;
                cursor: move;

                & svg {
                    fill: #ffffff;
                }
            }
        }

        & svg {
            fill: $blue;
        }


        &.empty {
            cursor: default;

            & .draggable-ico {
                background-color: #EBEBEB;
                cursor: default;

                & svg {
                    fill: #808080;
                }

                &:active {
                    background-color: #EBEBEB;
                    cursor: default;

                    & svg {
                        fill: #808080;
                    }
                }
            }

            &:hover {
                border-color: #E6E6E6;
            }
        }

        &.dragout {
            border-color: $blue;

            .ico {
                border: 1px dashed $blue;
            }
        }

        &.dragwhereto {
            border: 1px dashed $blue !important;

            & .draggable-ico {
                opacity: 0;
            }
        }

        &.dragin {
            background-color: rgba($blue, .08);
        }

    }
}

.created-part-item {
    margin-bottom: $small-offset
}

.forms-buttons {
    z-index: 1;
    position: fixed;
    bottom: 24px;
    right: 100px;
    box-shadow: 0px 4px 16px rgba(54, 56, 57, 0.16);
    padding: 12px;
    display: flex;
    background-color: white;
    width: 400px;

    & button {

        &:first-child {
            margin-right: 24px;
        }
    }
}

.progress-bar {
    padding: $small-offset 0 8px;

    &-date {
        display: flex;
        justify-content: space-between;

        &__item {
            color: $blue;
            font-size: 16px;
            line-height: 21px;
            font-family: $font-book;
        }
    }

    &-scale {
        width: 100%;
        height: 8px;
        border: 1px solid $blue;
        background-color: $blue;
        border-radius: 4px;
        position: relative;
        overflow: hidden;

        &__done {
            position: absolute;
            left: -55%;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: #ffffff;
        }
    }
}

.custom-field {
    margin-bottom: $small-offset;
}

.select {

    & li {
        padding: 8px;
        cursor: pointer;
        @include transition(300ms);

        &.active {
            background-color: rgba($blue, .08);
        }
    }
}

.steps {

    & * {
        list-style: none;
        padding: 0;
    }

    & li {
        position: relative;
        font-family: $font-book;
        padding: 5px 15px;
        font-size: 16px;
        line-height: 21px;
        color: #282A2B;
    }

    & .dot {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid #C4C4C4;
    }

    & .first-step {

        & > li {
        }
    }

    & .second-step {

    }
}

.checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    & svg {
        @include transition(300ms);
        margin-right: $base-offset;
        width: $small-offset;

        &.checked {
            fill: $blue;
        }
    }
}

.id {
    font-size: 14px;
    line-height: 18px;
    color: #282A2B;
}

.status {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    padding: 2px 12px;
    border-radius: 12px;
    color: #FFFFFF;
    margin-left: 10px;
    margin-right: 10px;
}


.parts-number {
    margin-left: 225px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $small-offset;

    .modal & {
        margin-left: 0;
    }

    &__item {
        font-family: $font-book;
        border: 1px solid #E6E6E6;
        border-radius: 4px;
        padding: 2px 8px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;
        @include transition(300ms);
        cursor: default;

        &:hover {
            background-color: rgba($blue, .08);
            color: $blue;
            border-color: $blue;

            & svg {
                fill: $blue;
            }
        }

        & span {
            width: 20px;
            height: 20px;
            transform: rotate(45deg);
            cursor: pointer;
        }

        & svg {
            @include transition(300ms);
        }
    }
}

.requesters {

    & .tag-list {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        & .user {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            & > div {
                display: flex;
                align-items: center;
            }
        }


        & > div {
            width: calc(100% / 2 - 12px);
            border: 1px solid #E6E6E6;
            border-radius: 4px;
            margin-bottom: 24px;
            padding: $small-offset;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            @include transition(300ms);

            &:hover {
                border-color: rgba($blue, .4);
            }

            &:active {
                border-color: rgba($blue, 1);
            }

            & .item-delete {
                margin-left: auto;
                width: $small-offset;
                height: $small-offset;
                cursor: pointer;

                & svg {
                    @include transition(300ms);
                }

                &:hover {

                    & svg {
                        fill: $blue;
                    }
                }
            }
        }
    }
}

.serials-block {

    & > input {
        margin-bottom: 24px;
    }

    button svg {
        margin-right: 6px !important;
    }

    & textarea {
        border: 1px solid rgba(0, 95, 206, 0.16);
        box-shadow: none;
        resize: none;
    }
}

.serials-number {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-y: scroll;
    max-height: 150px;

    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        line-height: 21px;
        font-family: $font-book;
        transition: 200ms;
        width: 100%;
        padding: 2px 8px;
        border: 1px solid #DDDDDD;
        border-radius: 4px;
        position: relative;
        margin-bottom: 8px;
        cursor: unset;
        max-height: 27px;

        & p {
            max-width: 88%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: default;
        }

        & button {
            position: absolute;
            right: 2px;
            padding: 0;
            border: 0;
            background-color: transparent;
        }

        & svg {
            width: 100%;
            height: 100%;
        }

        &:hover, {
            background: rgba($blue, .08);
            color: $blue;
            border-color: $blue;

            & svg {
                fill: $blue;
            }
        }
    }

    & svg {
        transition: 200ms;
    }

    & button {
        width: 16px;
        height: 16px;
    }
}

@keyframes dot-keyframes {
    0% {
        opacity: .4;
        transform: scale(1, 1);
    }

    50% {
        opacity: 1;
        transform: scale(1.2, 1.2);
    }

    100% {
        opacity: .4;
        transform: scale(1, 1);
    }
}
