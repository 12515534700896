
.period {
  margin: 0 0 $small-offset;
  background: #FFFFFF;
  border: 1px solid #28CD56;
  border-radius: 2px;
  padding: $small-offset;

  &-head {
    display: flex;
    justify-content: space-between;
  }

  &-buttons {
    display: flex;

    & button {
      font-size: 16px;
      line-height: 21px;
      border: 0;
      font-family: $font-medium;
      position: relative;
      margin-right: 30px;
      padding: 8px 4px;
      display: block;
      background-color: transparent;

      &:focus {
        outline: 0;
        bottom: 0;
      }

      &:first-child {
        margin-left: 4px;
      }

      &:last-child {
        margin-right: 0;
      }


      &.active {
        color: #28CD56;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% + 8px);
          height: 2px;
          background-color: #28CD56;
        }
      }
    }
  }

  & .period-reset {

    & button {
      background: rgba(40, 205, 86, 0.16);
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 2px;
      color: #282A2B;
    }
  }

  & .period-list {
    list-style: none;
    padding: 0;
    margin: 30px 0 0;

    &__item {
      font-family: $font-book;
      font-size: 16px;
      line-height: 24px;
      color: #363839;

      & .history-btn {
        display: inline-block;
        margin-left: 8px;
        padding: 0;
        border: none;
        background: transparent;
        color: #005FCE;
        text-decoration: underline;
        cursor: pointer;
      }
    }
& .red {
            color: $red;
        }
    }
}
