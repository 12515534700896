.opportunity-converter {
  $button-size: 48px;
  position: relative;
  width: 100%;
  color: $darker-grey;

  &__step {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &.hidden {
      animation: hideStep .3s ease-in-out forwards;
    }

    &.shown {
      animation: showStep .3s ease-in-out forwards;
    }

    &--parts {
      & .ls-form {
        margin: 0;

        label {
          display: none;
        }

        input {
          color: black;
          margin-bottom: $base-offset / 4;
          padding: $base-offset / 4 - 1px $base-offset / 2;
          line-height: 16px;
        }
      }

      & label {
        display: block;
        text-align: center;
        font-family: $font-book;
        font-size: 9px;
      }

      & .roundButton {
        display: block;
        margin: $base-offset / 2 auto 0;
        width: 100%;
        padding: 0;
        // padding: $base-offset / 4 $base-offset / 2;
      }

      &__link {
        display: block;
        margin-top: $base-offset / 2;
        font-size: 10px;
        font-family: $font-book;
        text-align: center;

        &:hover {
          text-decoration: underline !important;
        }
      }
    }

    &--converted {
      font-family: $font-book;
      color: $green;
      text-align: center;

      &__icon {
        display: block;
        font-size: 32px;
        margin-bottom: $base-offset / 2;
      }

      &__text {
        font-size: 14px;
      }
    }
  }

  &__title {
    font-size: 16px;
    font-family: $font-book;
    font-weight: 500;
    margin-bottom: $base-offset / 2;

    &__back {
      display: inline-block;
      margin-right: $base-offset / 4;
      color: rgba($blue, .8);

      &:hover {
        @include transition(.3s);
        color: $blue;
      }
    }

    &--center {
      text-align: center;
    }
  }

  &__buttons-grid {
    @include clear();
    padding: 0 $base-offset / 2;

    &__column {
      float: left;
      width: 50%;
      padding: $base-offset / 4;
    }
  }

  &__button {
    width: $button-size;
    text-align: center;
    margin: 0 auto;

    &--active {
      & .icon {
        @include transition(.3s);
        background-color: #fff;
        box-shadow: 0 0 6px 0 rgba($blue, .5);
      }
    }

    &__link {
      position: relative;
      display: inline-block;
      width: $button-size;
      height: $button-size;
      font-size: 24px;
      color: $blue;
      background-color: transparent;
      border-radius: 4px;

      border: 2px solid $blue;

      @include transition(.3s);
      box-shadow: 0 0 0 0 rgba($blue, .5);
      //box-shadow: inset 0 0 0 2px $blue;

      & i {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate3d(-50%, -50%, 0);
      }

      &:hover {
        background-color: #fff;
        @include transition(.3s);
      }
    }

    &__label {
      text-align: center;
      margin: $base-offset / 2 (-$base-offset / 2) 0;
      color: $darker-grey;
      font-size: 10px;
      display: block;
      text-align: center;
    }
  }
}

@keyframes hideStep {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes showStep {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
