.file-gallery {
  margin-top: 16px;
  position: relative;
  $file-height: 100px;
  @include clear();
  @include overflow();

  & .file-wrapper {
    position: relative;
    @include transition(.3s);
    display: flex;
    flex-wrap: wrap;
  }

  & .cell-3 {
    width: calc(100% / 3 - 16px);
    margin-right: 24px;
    margin-bottom: 24px;

    &:nth-child(3) {
      margin-right: 0;
    }
  }


  & .file {
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    max-height: 100px;
    @include transition(300ms);

    &:hover {
      border-color: $blue;

      & .icon {

        & svg:first-child {
          opacity: 1;
        }

        & svg:last-child {
          opacity: 0;
        }
      }
    }

    &-preview {
      width: 100%;
      padding: $base-offset;
      float: left;
      position: relative;
      min-width: 40px;
      height: 100%;
      display: flex;
      flex-direction: column;
      @include overflow();


      & .file-info {
        position: relative;
        display: flex;
        align-items: center;
        color: black;

        & svg {
          width: 20px;
          min-width: 20px;
          margin-right: 5px;
        }

        & .format {
          font-size: 16px;
          margin-right: 5px;
        }

        & .size {
          margin-top: 3px;
          margin-left: 5px;
          font-size: 14px;
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($dark, .4);
        opacity: 0;
        @include transition(.3s);
      }

      &::before {
        content: '\f019';
        font-family: FontAwesome;
        font-size: 14px;
        line-height: 16px;
        width: 16px;
        text-align: center;
        color: #fff;
        display: block;
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        opacity: 0;
        @include transition(.3s);
      }

      & img {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
      }

      &-title {
        color: black;
        margin-bottom: 5px;
        font-size: 16px !important;
        line-height: 21px;
        font-family: $font-book;
        width: 90%;
        word-wrap: break-word;
        position: relative;
      }

      & .file-extension {
        display: block;
        line-height: 40px;
        color: $darker-grey;
        background-color: $lighter-grey;
        font-size: 12px;
        text-transform: uppercase;
        width: 100%;
        height: 100%;
        text-align: center;
      }

    }

    &-info {
      margin-top: auto;
      position: relative;

      & p {
        display: block;
        line-height: $base-offset * 1.25;
        height: $base-offset * 1.25;
        font-size: 12px;
        font-family: $font-book;

        &.name {
          font-family: $font-book
        }

        &.size {
          color: $darker-grey;
        }

        font-weight: 500;
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: $base-offset * 2;
        height: 100%;
        background-color: #fff;
      }

      & .icon {
        position: relative;

        & svg {
          @include transition(300ms);
        }
      }

      & .download {
        position: absolute;
        top: 3px;
        opacity: 0;
        fill: $blue;
      }
    }

    &-delete {
      position: absolute;
      z-index: 2;
      top: 16px;
      right: 16px;
      display: block;
      width: 24px;
      height: 24px;
      padding: 3px;
      border-radius: 2px;
      @include transition(.3s);

      &:hover {
        background: rgba($red, 0.08);

        & svg {
          fill: $red;
        }
      }

      & svg {
        width: 100%;
        fill: black;
        @include transition(.3s);
      }
    }
  }

  & .controls {
    position: absolute;
    right: 0;
    top: 0;
    width: $base-offset;
    height: 100%;

    & button {
      position: absolute;
      left: 0;
      width: 100%;
      height: $base-offset;

      display: block;
      border: 0;
      background: none;
      padding: 0;

      font-size: 18px;

      & .fa {
        float: left;

        &::before {
          display: block;
          line-height: $base-offset;
          width: $base-offset;
          height: $base-offset;
          text-align: center;
        }
      }

      &.top {
        top: $base-offset / 4;
      }

      &.down {
        bottom: $base-offset / 4;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        @include transition(.3s);
        cursor: pointer;
        transform: scale(1.2);
      }

      &:disabled {
        color: $lighter-grey;

        &:hover {
          transform: none;
          cursor: default;
        }
      }
    }
  }
}
