.contract-profit {
  padding: $base-offset * 1.5;

  &__total {
    font-family: $font-book;
    font-size: 18px;
    font-weight: 500;
    color: $green;
    display: block;
    margin-bottom: $base-offset / 2;
    text-align: right;
  }

  &__table {
    width: 100%;
    margin-bottom: $base-offset * 2;
  }

  &__cell {
    font-size: 12px;
    padding: 4px;
    color: $dark;

    &--title {
      padding-top: 4px + $base-offset / 2;
      border-bottom: 1px solid $grey;
      font-weight: 500;
    }

    &--date {
      color: $darker-grey;
    }

    &--price, &--left {
      font-weight: 500;
      font-family: $font-book;
      font-size: 14px;
    }

    &--price {
      color: $orange;
    }

    &--left {
      color: $green;
    }
  }

  &__empty {
    color: $grey;
    font-family: $font-book;
    font-weight: 500;
    font-size: 18px;
    padding-top: 32px + $base-offset / 2;
    margin-bottom: $base-offset * 2;

    display: block;
    position: relative;
    text-align: center;

    & .fa {
      position: absolute;
      font-size: 32px;
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 32px;

      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__summary-row {
    margin-bottom: $base-offset / 2;

    &:last-child {
      margin: 0;
    }
  }
}


.profits {

  & .contract-profit,
  & .modal-window-content {
    padding: 0;
  }

  &-period {
    padding-bottom: $small-offset;
    border-bottom: 1px solid #E6E6E6;
    max-height: 40vh;
    overflow-y: scroll;
  }

  & .date-period {
    border-bottom: 1px solid #E6E6E6;

    &:last-child {
      margin-bottom: 0;
    }

    & h3 {
      font-family: $font-semibold;
      font-size: 16px;
      line-height: 20px;
      color: #363839;
      margin-bottom: 15px;
    }
  }

  &-tile {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: $small-offset;

    & .summary-row {
      padding: 16px;
      width: calc(100% / 2 - 10px);
      margin-bottom: 20px;
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      border-radius: 2px;
    }
  }

  &-row {
    display: flex;
    margin-bottom: $base-offset;
  }

  & .message {
    font-size: 16px;
    line-height: 24px;

  }

  & .decrease {
    color: $red;
  }

  & .total-value-left {
    color: #28CD56;
  }

  & .decrease,
  & .increase {
    margin-left: auto;
    margin-right: 32px;
  }

  & .total-value-left,
  & .decrease,
  & .increase {
    font-size: 16px;
    line-height: 24px;
    font-family: $font-medium;
  }
}

.summary-row {
  font-size: 14px;
  font-family: $font-book;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include overflow();


  &__label, &__value {

  }

  & .hour-glass {
    width: auto;
  }

  &__label {
    font-family: $font-medium;
    color: $darker-grey;
    font-size: 16px;
    line-height: 24px;
  }

  &__value {
    font-weight: 500;

    &--green,
    &--red,
    & .hour-glass__days {
      font-size: 24px;
      line-height: 24px;
      font-family: $font-medium;
    }

    &--green {
      color: $green;
    }

    &--red {
      color: $red;
    }
  }
}
