@keyframes message-appear {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.activity-log {

  .message-section {
    border: 2px solid #f5f5f5;
    padding: 0 3em;
    margin: -2px;
  }

  &.support {
    border: 1px solid #f5f5f5;
    padding: 1rem 1.5rem;
  }

  .add-comment-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: $small-offset;

    & .send-area {
      display: flex;
      margin-top: 10px;
      flex-direction: row;
    }

    .message-type {
      font-weight: 600;
      font-size: 18px;
      margin-left: 56px;
      margin-top: 10px;
    }

    & .private-tabs {
      margin: 0 -3em;
      display: flex;
      background-color: #f5f5f5;

      & .private-tab-item {
        width: 100%;
        display: inline-block;
        padding: 12px 3em;
        color: #7A7F82;
        cursor: pointer;
        border-bottom: 2px solid #f5f5f5;

        &.active {
          background-color: #fff;
          border-bottom: 2px solid transparent;

        }
      }
    }

    & .ls-form {
      width: calc(100% - 60px);
      display: block;
      margin-bottom: 0;

      & input {
        color: black;
        border: 1px solid rgba($blue, 0.16);
        border-radius: 2px;
        font-family: $font-medium;
      }
    }

    .ls-form {
      width: 100%;

      textarea {
        margin-bottom: 20px;
      }
    }

    .roundButton {
      align-self: flex-end;
      max-width: 120px;
    }

  }

  .user-avatar {
    margin-right: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F93F17;
    color: #ffffff;
  }

  .activity-log-send {
    display: flex;
    margin: 15px 0;
    align-items: center;

    .roundButton {
      width: 40px;
      margin-top: 4px;

      svg {
        margin-right: 0;
      }
    }
  }
  .activity-list{
    min-height: 265px;
  }
  .activity-list-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    border-radius: 10px;
    padding: 15px 10px 0 10px;
    animation-delay:0.5s;
    animation: message-appear 0.5s ease-in-out;

    &.internal {
      background-color: #f5f5f5;
    }

    &.reply {
      background: linear-gradient(0deg, #EBF2FA, #EBF2FA), #005FCE;
    }

    & .user {
      display: flex;

      &-info {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        &__name {
          font-size: 16px;
          line-height: 21px;
          color: #363839;
          margin-bottom: 5px;
          font-family: $font-semibold;
        }

        &__status {
          display: flex;
          align-items: center;

          svg {
            height: 25px;
            min-width: 25px;
            margin-right: 10px;
            color: grey;
          }

          .createAt {
            font-size: 14px;
            line-height: 18px;
            color: #7A7F82;
            white-space: pre;
          }
        }
      }
    }


    & .message {
      font-size: 16px;
      line-height: 21px;
      color: #363839;
      padding: 8px 16px 8px 8px;
      margin-left: 56px;
      border-radius: 2px;
      white-space: pre-wrap;

      &.private {
        background: #fdf1dd;
        font-style: italic;
      }
      .dimension-activities{
        overflow: hidden;
        img {
          display: block;
          overflow: hidden;
          cursor: pointer;
          &.horizontal{
            max-height: 50px;
            max-width: 275px;
          }
          &.vertical {
            max-width: 270px;
            max-height: 220px;
          }
          &.fix-height{
            max-width: 250px;
            max-height: 200px;
          }
          &.common{

          }
        }
      }


      &-attachments {
        padding: 0 10px;
        list-style: none;

        .attachment {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &-icon {
            max-height: 25px;
            max-width: 25px;
            margin-right: 10px;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
