.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 25;
}

.engineer {

  & > div {
    display: flex;
    align-items: center;
    text-align: left;
  }
}

.avatar {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.popover-content {

  & .filters-list {
    display: block;
    padding-right: 0;
  }

  & .filters-wrapper {
    display: flex;
    flex-wrap: wrap;

    & .ls-form-wrapper {
      width: calc(100% / 3 - 10px);
      margin-right: 15px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      & .ls-textinput {
        display: flex;
        flex-direction: column;

        & input {
          border: 1px solid #DDDDDD;

          &:focus {
            border: 1px solid rgba($blue, 0.16);
          }

          &:focus {
            border: 1px solid rgba($blue, 1);
          }
        }
      }

      & label {
        font-size: 16px;
        line-height: 20px;
        color: #282A2B;
        margin-bottom: 16px;
      }

      & input {
        color: black;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 2px;

        &:hover {
          border-color: rgba($border-color, .3);
        }
      }

      & .bp3-popover-wrapper {
        width: 100%;
        display: block;
      }

      & .bp3-popover-target {
        width: 100%;
      }

      & .css-bg1rzq-control {
        border-radius: 1px;
        border-color: #E6E6E6;
        height: 40px;
      }
    }

    & .ls-range-inputs {
      width: 100%;

      & input.left-input {
        margin-left: 0;
      }
    }
  }

  & .buttons {
    padding-top: 24px;
    width: 100%;
    border-top: 1px solid #E6E6E6;
    display: flex;
    justify-content: flex-end;


    & button {
      white-space: nowrap;
      margin-left: 15px;

      &:nth-child(1) {
        @include transition(300ms);
        @include secondaryBtn();
      }

      &:nth-child(2) {
        @include transition(300ms);
        @include tertiaryBtn();
      }
    }
  }
}


.dashboard {
  height: 100%;
  border-radius: 4px;

  &.profits {
    border: 0;
    overflow-y: scroll;

    & .grid-wrap {
      border: 1px solid #EDEDED;
    }

    & .grid {
      overflow: visible;
    }
  }

  &__summaries {
    display: flex;
    flex-wrap: wrap;
    margin: 0 (-$base-offset / 2) 0;
    justify-content: space-between;

    & + .grid-wrap {
      height: calc(100% - (90px + #{$base-offset})) !important;
    }
  }

  &__summary-widget {
    width: calc(100% / 4 - 10px);
    margin-bottom: 10px;
  }
}

.grid-wrap {
  height: 100%;
}

.grid-head {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.ls-range {

  & .ls-range-inputs {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    & .ls-form {
      background: transparent !important;
      margin-bottom: 0;
      padding: 0;
    }

    & input {
      color: black;
      margin-left: 0;
    }
  }
}

.filters {
  display: flex;


  & .filters-list {
    display: flex;
    flex-wrap: wrap;

    & .toggle-inputs {
      display: none !important;
    }

    & .ls-picker {

      &:hover {

        & .tertiaryBtn {
          color: $blue !important;
        }
      }

      & .secondaryBtn {
        display: none;

        &.tertiaryBtn {
          display: block;
          width: 100%;
          background-color: transparent;
          border-color: transparent;
          color: black;
          font-size: 14px;
          font-family: $font-light;
          padding-top: 7px;
          padding-left: 6px;
          padding-right: 6px;
        }
      }
    }

    & .ls-form-wrapper div[class$="singleValue"] {
      position: relative;
      transform: none;
      -webkit-transform: none;
      margin-left: 6px;
      font-family: $font-light;
      margin-top: 1px;
    }

    & .ls-form-wrapper div[class$="multiValue"] {
      font-family: $font-light;
    }

    & input {
      background-color: transparent !important;
    }

    & > div:not([class]) {
      margin-right: 8px;
      margin-bottom: 8px;
      position: relative;
      max-height: 38px;

      &:last-child {
        margin-right: 20px;
      }

      &:hover {

        & svg {
          fill: $blue;
        }
      }
    }

    & .ls-form {
      max-height: 32px;

      &.multiselect {
        width: 100%;
        border-radius: 2px;

        & > div {

          & > div {
            Х
            & > div:last-child {
              display: none;
            }
          }
        }
      }
    }
  }

  & .delete-filter {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 7px;
    left: 8px;

    opacity: .8;

    & svg {
      cursor: pointer;
      width: 20px;
      transform: rotate(45deg);
      @include transition(300ms);
    }
  }

  & .ls-form {
    padding: 8px;
    display: flex;
    align-items: center;
    max-height: 38px;
    background: #F5F5F5;
    border-radius: 1px;
    @include transition(300ms);
    cursor: pointer;

    & input {
      border: 0;
    }

    &.ls-select,
    &.ls-range {
      flex-direction: row;

      & .css-yk16xz-control {
        background-color: transparent;
        border: 0;
        cursor: pointer;
      }

      & .css-1hwfws3 {
        padding: 0;
      }

      & .css-1hb7zxy-IndicatorsContainer,
      & .css-b8ldur-Input,
      & .css-xb97g8 {
        display: none;
      }

      & .css-1rhbuit-multiValue {
        background-color: transparent;
      }

      & .css-12jo7m5 {
        padding: 7px 5px;
        transform: translateY(1px);
        font-size: 14px;
      }
    }

    &.ls-daterange {
      min-width: unset;
      flex-direction: row;

      & * {
        border: 0;
      }
    }

    &:hover {
      background: rgba(#005FCE, .08);

      & * {
        color: $blue;
      }


      & * {
        color: $blue;
      }

      & svg {
        fill: $blue;
      }
    }

    & input {
      font-family: $font-light;
      background-color: transparent;
      pointer-events: none;
      padding: 7px 5px;
      transform: translateY(1px);
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: black;
      @include transition(300ms);
    }

    & label {
      transform: translateY(1px);
      white-space: nowrap;
      padding-left: 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #000000;
      margin-bottom: 0;
      position: relative;
      cursor: pointer;
      @include transition(300ms);

      &::after {
        content: 'is';
        margin-left: 5px;
      }
    }
  }
}

.control-buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding: 3px;

  .profits & {
    flex-direction: row-reverse;

    & button {
      margin: 0;

      &:last-child {
        margin-right: 24px;
      }
    }
  }

  & .roundButton {

    &.create-tickets {
      background: $border-color;
      color: #ffffff;
      transition: 300ms all;

      &:hover {
        background: lighten($border-color, 15%)
      }

      & svg {
        fill: #ffffff;
      }
    }

    &:last-child {
      margin-left: 16px;
    }

    &.secondaryBtn {
      @include transition(300ms);
      @include secondaryBtn();
    }

    &.tertiaryBtn {
      @include transition(300ms);
      @include tertiaryBtn();
    }
  }
}

.tabs {
  height: auto;
  display: flex;
}

.main-table {
  width: 100%;
  padding: 0 $small-offset 40px;
  background-color: white;

  & .table-body {
    padding-bottom: 60px;
  }

  &.table-profit {

    & .table-row {
      grid-template-columns: 24px 25% 10% 10% 10% 10% 10%;
    }
  }


  &.table-projects {

    & .table-row {
      grid-template-columns: 24px 25% 15% 20% 20%;
    }
  }

  &.table-opportunities {

    & .table-row {
      grid-template-columns: 15% 10% 10% 8% 5% 10% 8% 15%;
    }
  }

  &.table-products {

    & .table-row {
      grid-template-columns: 30% 15% 15%;
    }
  }

  &.table-systems {

    & .table-row {
      grid-template-columns: 35% 10% 10% 10% 10%;
    }
  }


  &.table-contracts {

    & .table-row {
      grid-template-columns: 35% 10% 10% 10% 10%;
    }
  }

  &.table-purchases {

    & .table-row {
      grid-template-columns: 25% 12% 15% 10% 3%;

      & svg {
        width: 20px;
      }
    }
  }

  &.table-customers {

    & .table-row {
      grid-template-columns: 25% 15% 10% 15%;
    }
  }

  &.table-suppliers {

    & .table-row {
      grid-template-columns: 25% 15% 10% 15%;
    }
  }

  &.table-tickets {

    & .table-row {
      grid-template-columns: 30% 10% 10% 10% 12%;
    }
  }


  &.table-support-requests {

    & .table-row {
      grid-template-columns: 20% 35% 20% 20%;

      &__item {
        overflow-wrap: break-word;
        white-space: normal;
        overflow-y: hidden;
        overflow-x: visible;
        max-height: 120px;
        align-items: normal;
      }
    }
  }

  &.table-sales {

    & .table-row {
      grid-template-columns: 25% 10% 10% 10% 10% 10%;
    }
  }

  .table-row {
    display: grid;
    grid-template-columns: 30px repeat(auto-fill, minmax(70px, 200px));
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    border-bottom: 1px solid #E6E6E6;
    position: relative;
    padding: $base-offset 0;
    text-align: left;
    @include transition(300ms);
    font-size: 16px;

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: -$small-offset;
      width: 120%;
      height: calc(100% + 2px);
      background-color: rgba($blue, 0.00);
      @include transition(300ms);
    }

    &:hover:not(.heading) {
      //border-color: transparent;

      &::before {
        background-color: rgba($blue, 0.08);
      }
      .other-info{
        background-color: inherit;
      }
    }

    &.active {
      padding: $base-offset;

      &:hover::before {
        background-color: #F0F0F0;
      }

      &::before {
        background-color: #F0F0F0;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.heading {
      font-family: $font-semibold;

      & * {
        font-size: 16px;
        line-height: 18px;
      }
    }

    & .title {
      line-height: 18px;
      color: #282A2B;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 4px;
    }

    &__item {
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 36px;
      display: flex;
      align-items: center;
      position: relative;

      & > div {
        width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & .secondaryBtn {
        border-color: transparent;
        background-color: transparent;
        font-size: 14px;
        display: flex;
        color: $blue;
        padding: 8px;
        justify-content: center;
        width: auto;
        cursor: pointer;
        @include transition(300ms);

        &:hover {
          border-color: $blue;
          background-color: rgba($blue, .08);
        }

        & svg {
          min-width: 16px;
          margin-right: 2px;
          fill: $blue;
        }
      }

      .gmail_quote {
        display: none;
      }
    }
  }

  & .profit-checkbox {
    width: $base-offset;
  }

  &
  .not-approved {
    border-bottom: 1px solid #E6E6E6;
  }

  .not-available {
    color: $red;
    font-weight: 600;
  }
}

.child-table {
  background-color: #FAFAFA;

  & .table-row {
    padding: $base-offset;

    &.heading {
      padding-top: 50px;
      border: 0;
    }
  }
}

.category {
  display: flex;
  align-items: center;

  & svg {
    min-width: 16px;
    margin-right: 5px;
    max-width: 20px;
  }
}

.status-cell {
  display: flex;
  align-items: center;

  .status-col {
    display: inline-block;
    grid-area: 1/2/2/3;
  }

  .status-ico {
    margin-right: 5px;
    color: #ffffff;
    border-radius: 8px;
    padding: 2px 8px;
    font-size: 12px;

    &.green {
      background-color: $green;
    }

    &.purp {
      background-color: #B850E9;
    }

    &.orange {
      background-color: $orange;
    }

    &.red {
      background-color: $red;
    }

    &.blue {
      background-color: #3ADAE4;
    }

    &.grey {
      background-color: #808080;
    }

    &.empty {
      background-color: $blue;

      &.orange {
        background-color: $orange;
      }
    }
  }
}

.pager {
  $size: 24px;
  transform: translateY(-80px);
  padding: 0 $small-offset;
  font-family: $font-book;
  font-size: 11px;
  line-height: $size;

  & > * {
    margin: 0 $base-offset / 4;
    min-width: $size;
    height: $size;
    border-radius: 24px;
    line-height: 20px;
    border: 1px solid $grey;
    background-color: transparent;
    text-align: center;
    cursor: pointer;

    &:hover {
      border-color: $blue;
    }
  }

  &-page.active {
    background-color: $blue;
    border-color: $blue;
    color: #fff;
  }

  &-elipsis {
    border: 0;
  }

  &-side {
    width: 36px;
  }

  &-offset {
    &-left {
      width: 30px;
      margin-left: $base-offset;
    }

    &-right {
      width: 30px;
      margin-right: $base-offset;
    }
  }
}

.other-info {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: $font-book;
  color: #7A7F82;
  @include transition(300ms);

  &.wrap {
    flex-wrap: wrap;
  }

  &.grid {
    display: grid;
    grid-template-columns: 1fr 6fr;

    .grid-date {
      display: flex;
      grid-area: 2/1/2/3;
      white-space: pre;
    }
    .status-col{
      grid-area: 1/2/2/-1;
    }
  }

  & .status-cell {

  }

  & span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }


  & .dot {
    font-size: 20px;
    margin: 0 4px;
    line-height: 20px;
    min-width: 8px;
  }

  & .date {
    margin-top: 1px;
    display: flex;
    position: relative;

    & span {
      color: #7A7F82;
      font-size: 16px;
      line-height: 18px;
    }
  }

}

.panel-wrap {

  & .filters-list {
    display: flex;
    flex-wrap: wrap;
    padding-right: 0;
  }

  & .buttons {
    width: 100%;
  }

  &.animating {
    @include overflow();
  }
}

.filters-button {

  & .roundButton {
    max-width: 90px;
    border-color: transparent;
  }
}

.filters-form {
  position: relative;

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  form {
    padding-right: 224px;

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    position: relative;

    & .buttonGroup {
      display: flex;

      & button {
        width: 48%;
      }

      & .roundButton {
        margin: 0 4px;
        max-width: 70px;
      }
    }
  }


  & .Select {
    height: 32px;
  }

  & .ls-daterange {
    min-width: 200px;

    & input {
      height: 38px;
    }
  }

  & .ls-range {

    & .ls-form {
      width: calc(100% / 2 - 5px);
    }

    input {
      color: black;

      &.left-input {
        margin-left: 4px;
      }
    }
  }

  &-config {
    display: block;
    position: absolute;
    font-size: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    top: 26px;
    right: $base-offset / 2;
    color: $dark;

    &::before {
      content: "\F013";
      display: block;
      font-family: FontAwesome, sans-serif;
      font-size: 24px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
    }

    animation: rotateCogBack .7s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;

    &:focus, &:hover {
      color: $dark;
      text-decoration: none;
    }

    &:hover {
      animation: rotateCog .7s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
    }

    @keyframes rotateCog {
      from {
        transform: rotateZ(0deg);
      }
      to {
        transform: rotateZ(90deg);
      }
    }

    @keyframes rotateCogBack {
      from {
        transform: rotateZ(90deg);
      }
      to {
        transform: rotateZ(0deg);
      }
    }
  }

  &.show {
    animation: showPanel .7s ease-in-out forwards;
  }

  &.hide {
    animation: hidePanel .7s ease-in-out forwards;
  }
}

.no-results {
  text-align: center;
  margin-top: 40px;

  &-text {
    margin: 0;
    padding: 0;
    font-family: $font-book;
    font-size: 14px;
    color: $darker-grey;

    &.title {
      font-family: $font-book;
      font-size: 18px;
      color: $dark;
      //text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  &-icon {
    background-image: url('./../../media/search-magnifier-with-a-cross.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  & i {
    font-size: 3em;
    color: #888;
  }

  &-icon, & i {
    display: block;
    width: 140px;
    height: 40px;
    margin: 20px auto;
  }

  &-icon {
    width: 43px;
  }
}

.sort-link {
  position: relative;

  &::after {
    content: '\f0dc';
    z-index: 1;
    font-family: FontAwesome;
    margin-left: $base-offset / 2;
    color: $grey;

    position: absolute;
    top: 6px;
    right: -$base-offset;
    line-height: 10px;
  }

  &::before {
    z-index: 2;
    color: $orange;
    position: absolute;
    font-family: FontAwesome;
    top: 0;
    right: -$base-offset;
    line-height: 10px;
    transition: .3s;
  }

  &:hover {
    &::before {
      content: '\f0dd';
    }
  }

  &.desc {
    &::before {
      content: '\f0dd';
    }

    &:hover {
      &::before {
        content: '\f0de';
      }
    }
  }

  &.asc {
    &::before {
      content: '\f0de';
    }

    &:hover {
      &::before {
        content: '\f0dd';
      }
    }
  }
}

.toggle-btn {
  display: block;
  height: 16px;
  width: 16px;
  position: relative;

  &.selected {

    & svg {
      fill: $blue;
    }

    &.red {

      & svg {
        fill: $red;
      }
    }
  }
}

.grid {
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  &-head {
    margin-bottom: $base-offset;
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      position: absolute;
    }

    & .header-title {
      margin-bottom: $base-offset;
    }

    .grid-panel {
      float: right;

      button {
        margin: 0 0 0 $base-offset / 2;
      }
    }
  }

  & .user-bar {
    padding: $small-offset;
  }

  & .pre-loader {
    top: 200px !important;
  }
}

.contract-history {

  & .popover-content {
    padding: 0;
  }

  &-item {
    padding: $small-offset;
    border-bottom: 1px solid #E6E6E6;

    &__heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    &__title {
      font-family: $font-medium;
      font-size: 16px;
      line-height: 32px;
      color: #282A2B;
    }

    & > div {
      display: flex;

      & p {
        font-size: 14px;
        line-height: 20px;
        color: #282A2B;
      }

      & .pre-text {
        font-family: $font-medium;
        font-size: 16px;
        margin-right: 4px;
        white-space: nowrap;
      }
    }

    & .true,
    & .false {
      font-family: $font-medium;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
      padding: 0 12px;
      border-radius: 22px;
    }

    & .true {
      background: #28CD56;

    }

    & .false {
      background: #F06262;
    }

    & .boxes {
      align-items: center;
      justify-content: space-between;

      &-item {
        padding: $base-offset;
        border: 1px solid #E6E6E6;
        border-radius: 2px;
        @include transition(300ms);
        height: 100px;
        width: calc(100% / 2 - 25px);

        &:hover {
          border-color: $blue;
        }

        & .ls-form {
          margin-bottom: 0;
          height: 32px;
          display: flex;
          justify-content: center;

          & label {
            min-width: unset;
          }
        }

        &__title {
          font-family: $font-semibold;
          margin-bottom: $base-offset;
          font-size: 14px;
          line-height: 18px;
          color: #363839;
        }

        & input[type=text] {
          background: rgba(0, 95, 206, 0.08);
          border-radius: 2px;
        }


      }
    }
  }
}

@keyframes showPanel {
  from {
    margin-top: -210px;
  }
  to {
    margin-top: 0;
  }
}

@keyframes hidePanel {
  from {
    margin-top: 0;
  }
  to {
    margin-top: -210px;
  }
}

@media (max-width: 768px) {
  .filters-form {
    form {
      padding-right: 0;

      .ls-form-wrapper {
        float: none;
      }

      .buttonGroup {
        position: static;
        width: 100%;
        margin: 25px 5px;

        button {
          display: inline-block;
        }
      }
    }
  }
}
