.opportunity {

    & .left-panel {
        min-width: 230px;
        width: 25%;
    }

    & .form-wrapper {
        min-width: unset;
        width: 50%;
    }

    & .right-panel {
        min-width: 300px;
        width: 25%;

        & .right-panel-content {
            height: 93%;
        }
    }

    .popover-content {

        & .chooses-content {

            & .cards {
                width: calc(100% / 2 - 12px);
            }

            @media only screen and(max-width: 1110px) {
                & {
                    margin-bottom: 60px;

                    & .cards {
                        width: calc(100% / 5 - 8px) !important;
                    }
                }
            }

            @media only screen and(max-width: 768px) {
                & {
                    margin-bottom: 0;
                    flex-wrap: wrap;


                    & .cards {
                        width: calc(100% / 3 - 8px) !important;
                        height: 32px;
                        position: relative;
                        padding: 4px;
                        min-height: 0;
                        min-width: 0;
                        margin-right: 4px;
                        background: rgba(0, 94, 184, 0.08);

                        &:nth-child(4),
                        &:nth-child(5) {
                            width: calc(100% / 2 - 8px) !important;
                        }

                        &.active {
                            background: $blue;

                            & p {
                                color: #ffffff;
                            }

                            & .cards-ico {
                                background-color: transparent;
                            }
                        }

                        &-ico {
                            padding: 0;
                            position: absolute;
                            background: transparent;
                        }

                        & > p {
                            margin-top: 0;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 34px;
                        }

                        & svg {
                            width: 24px;
                        }
                    }
                }
            }

            @media only screen and(max-width: 589px) {
                & {

                    & .cards {
                        width: calc(100% / 2 - 8px) !important;

                        &:last-child {
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }
}

.opportunity-part-list {
    margin-bottom: 24px;
    margin-top: -12px;

    *::after {
        content: none;
        display: none;
        clear: none;
    }

    .remove-opportunity-part {
        float: right;
    }

    .opportunity-part-realization {
        margin-left: 12px;
        display: block;
    }
}

.opportunity-contract-type {
    .remove-opportunity-contract {
        float: right;
        display: inline-block;
        font-weight: bold;
    }

    .editable-field {
        width: 80%;
    }
}

.create-quotes {
    min-width: 400px;
    width: auto;
    @media only screen and(max-width: 589px) {
        min-width: 320px;
    }

    & .step-title {
        font-size: 18px;
        line-height: 24px;
        font-family: $font-medium;
        color: #282A2B;
        margin-bottom: 16px;
    }

    & .cards {
        min-width: 95px;
        height: 95px;
        margin-right: $small-offset;
        margin-bottom: 56px;

        &:last-child {
            margin-right: 0;
        }

        & .cards-ico {
            background: rgba(0, 94, 184, 0.08);
            border-radius: 2px;
            padding: 32px;
            @include transition(300ms);

            & svg {
                fill: #005EB8;
            }
        }

        & > p {
            margin-top: 8px;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            color: #282A2B;
            font-family: $font-medium;
        }

        &.active {

            & .cards-ico {
                background: $blue;

                & svg {
                    fill: #ffffff;
                }
            }

            & > p {
                color: $blue;
            }
        }
    }

    & .two-columns {
        display: flex;
        justify-content: space-between;

        & > div {
            width: 48%;
        }
    }

    & .ls-form {

        & input,
        & textarea {
            border: 1px solid #E6E6E6;
            border-radius: 1px;

            &:focus {
                border-color: $blue !important;
            }
        }


    }
}


.quotes-item {
    padding: $small-offset;
    position: relative;

    &__status {
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
        padding: 0 8px 2px;
        border-radius: 17px;
    }

    &__quote-id {
        width: 100%;
        margin: 8px 0;
        font-size: 16px;
        line-height: 21px;
        font-family: $font-medium;
        color: #282A2B;
    }

    &-info {
        font-size: 16px;
        line-height: 21px;
        color: #7A7F82;

        & .row {
            display: flex;
            margin-bottom: 8px;
        }

        &__title {
            font-family: $font-medium;
            min-width: 40%;
        }

        &__desc {
            font-family: $font-book;
        }
    }
}

.dots {
    position: absolute;
    right: 20px;
    padding: 4px;
    width: 24px;
    cursor: pointer;
}

.quote-menu {
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    box-shadow: 0 8px 16px rgba(122, 127, 130, 0.08);
    border-radius: 2px;
    padding: 5px 0;

    & .popover-content {
        padding: 0;
    }

    & span {
        display: flex;
        align-items: center;
        padding: 8px 24px;
        cursor: pointer;
        white-space: nowrap;
        @include transition(300ms);

        &:hover {
            background-color: rgba($blue, 0.10);
        }

        & svg {
            width: 16px;
            min-width: 16px;
            margin-right: 5px;
        }
    }
}

.activity-log-popup {

    & .popover-content {
        max-height: 70vh;
        overflow-y: scroll;

        & form {
            flex-direction: row !important;
        }
    }
}

.serials-block {

    & button {
        margin-bottom: 12px;
    }

    &__radios {
        display: flex;
        margin-bottom: 12px;

        & input {
            width: auto;
            margin-right: 24px;
        }
    }

    & button {
        margin-bottom: 0;
        margin-top: 12px;
    }
}

.project-time-entry {

    & input {
        margin-bottom: 24px;
    }

    & .time-entry-list {
        margin-top: 24px;
    }


    & button {
        margin-bottom: 24px;
    }

    & .other-info {
        margin-bottom: 12px;
        border-radius: 4px;
        border: 1px solid transparent;
        transition: 300ms;
        position: relative;
        padding: 2px 24px 2px 12px;

        &:hover {
            background-color: rgba($blue, .16);
            border-color: $blue;
        }

        & .name {
            color: black;
        }

        & .del {
            border: 0;
            width: 14px;
            padding: 0;
            position: absolute;
            top: 5px;
            right: 8px;
            cursor: pointer;
        }
    }
}

.center-form-container.quote-details {

    & .forms-buttons {
        z-index: 45;
    }

    & .activity-log-button {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
        display: flex;

        &:hover {

            & p {
                color: $blue;
            }
        }

        & svg {
            margin-right: 10px;
            width: 20px;
        }

        & p {
            color: black;
        }
    }

    & .popover-content {

        & form {
            flex-direction: column;
        }
    }

    & .form-wrapper {
        width: 35%;
        min-width: unset;

        @media only screen and(max-width: 1110px) {
            width: 35%;
        }

        & .select {
            & > div {
                border-color: rgba($blue, .16);
            }
        }
    }

    & .right-panel {
        width: 65%;
        padding: $small-offset;
        overflow-y: scroll;
        padding-bottom: 60px;

        & .back {
            margin-bottom: 44px;
        }

        &-heading {
            margin: 0 -24px $small-offset;
            padding: 0 $small-offset;
            border-bottom: 1px solid #E6E6E6;
        }

        & .three-columns {
            display: flex;
            justify-content: space-between;
            margin-bottom: $small-offset;

            & button {
                background-color: rgba($blue, .16);
                color: black;
                flex: 1;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                &.active {
                    background-color: $blue;
                    color: #ffffff;
                }
            }
        }

        & .autocomplete-input {
            margin-bottom: $small-offset;

            & input {
                max-height: 38px;
            }
        }
    }

    & .step-title {
        font-family: $font-medium;
        font-size: 18px;
        line-height: 24px;
        color: #282A2B;
        margin-bottom: $small-offset;
    }

    & .chooses-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 24px;

        & .cards {
            margin-right: 0;

            & > p {
                margin-top: 0;

            }

            &.active {

                & p {
                    color: #ffffff
                }
            }
        }

        & .cards,
        & .tertiaryBtn {
            width: calc(100% / 5 - 8px);
            background: rgba(0, 95, 206, 0.08);
            border-radius: 2px;
            font-family: $font-book;
            font-size: 16px;
            line-height: 21px;
            padding: 6px 9px;
            color: #282A2B;
            text-align: center;
            cursor: pointer;
            max-height: 34px;
            border: 1px solid transparent;
            @include transition(300ms);


            &:hover {
                background: rgba(0, 95, 206, 0.08);
                border-color: $blue;
            }


            &.active {
                background: #005FCE;
                border-radius: 2px;
                color: #FFFFFF;
                font-family: $font-medium;
            }
        }

    }

    & .systems {

        & .components-table {

            &-row {
                grid-template-columns: 15.72% 20% 15.05% 15.05% 26px;

                .system-title-header {
                    display: flex;
                    align-items: center;

                    .browse {
                        display: inline-block;
                    }
                }
            }
        }
    }


    & .components-table {
        min-height: 42vh;
        max-height: 42vh;
        overflow-y: scroll;


        &-row {
            display: grid;
            grid-template-columns: 40.72% 20.05% 15.05% 26px;
            justify-content: space-between;
            font-size: 14px;
            line-height: 18px;
            font-family: $font-book;
            border-bottom: 1px solid #E6E6E6;
            padding: 16px 15px 16px 0px;

            &.heading {
                font-family: $font-semibold;
            }

            & .select {
                text-align: left;
            }

            & > div {
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:first-child {
                    font-family: $font-medium;
                }
            }

            & .browse {
                margin-left: 5px;
                width: 16px;
                height: 16px;
                min-width: 16px;
                cursor: pointer;
                fill: $blue;
                display: block;

                & svg {
                    width: 100%;
                    height: 100%;
                }
            }

            & .other-info {
                align-items: center;
                flex-direction: row !important;
                justify-content: flex-start;

                & .checkbox {

                    & label {
                        flex-direction: row-reverse;
                        justify-content: space-between;
                    }
                }

                & .filter-input {
                    margin-bottom: 24px;
                }

                & .checkbox-list {
                    //margin-top: 24px;
                    max-height: 160px;
                    overflow-y: scroll;
                }
            }
        }

        & .ls-form {
            margin: 0;
            height: 32px;
            width: 100%;
            text-align: center;

            & input {
                height: 32px;
                border: 1px solid rgba(0, 95, 206, 0.16);
            }

            & .numberinput-wrap {

                & > input {
                    text-align: center;
                    padding: 0 10px;
                }

                & button {
                    top: 0 !important;
                }
            }
        }

        & .remove {
            display: flex;
            align-items: center;
            padding: 5px;
            cursor: pointer;

            & svg {
                fill: #F06262;

            }
        }
    }

    & .additional-info {
        margin-top: 32px;
        margin-bottom: 24px;

        & .two-columns {
            display: flex;
            justify-content: space-between;

            & > div {
                width: 48%;
            }
        }

        .ls-form {
            display: flex;
            flex-direction: column;
            margin-bottom: $small-offset;

            & label {
                align-self: flex-start;
                margin-bottom: $base-offset;
            }

            & input,
            & textarea {
                border: 1px solid rgba(0, 95, 206, 0.16);

                &:hover {
                    border-color: $blue;
                }
            }
        }
    }
}
