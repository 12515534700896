.file-uploader {
  $add-button-size: 120px;
  position: relative;
  margin: 0 -24px;

  & > div {
    padding: 0 24px 24px;
  }

  & .drag-text {
    position: absolute;
    bottom: 20%;
    left: 0;
    width: 100%;
  }

  & .add-files-button {
    position: relative;
    border: 0;
    background: none;
    display: block;
    width: 100%;
    padding: 0;
    height: $add-button-size;
    background: rgba($blue, .1);
    justify-content: center;

    &:focus, &:active {
      outline: none;
    }

    & .fa {
      font-size: 24px;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      color: $darker-grey;
    }
  }

  & .buttons-block {
    padding: 0 24px;
  }

  & .file-list {
    padding: 0;
    @include overflow(visible, scroll);
  }

  & .file-field {
    display: flex;
    padding: 24px;
    align-items: center;
    font-size: 12px;
    font-family: $font-book;
    color: $darker-grey;
    line-height: $base-offset;
    position: relative;

    & > button {
      margin-left: auto;
    }

    & .file-type {
      float: left;
      margin-right: $base-offset / 2;
      height: $base-offset;

      &::before {
        content: '\f016';
        font-size: 16px;
        font-family: FontAwesome;
      }
    }

    & .file-progress-wrap {
      display: block;
      position: absolute;
      bottom: $base-offset / 2;
      left: $base-offset;
      height: 5px;
      width: 60%;
      background: rgba($blue, .15);
      border-radius: 10px;
      @include overflow();

      & .file-progress {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 0%;
        border-radius: 10px;
        background: rgba($blue, .5);
      }
    }

    & .file-delete {
      background-color: transparent;
      border: 0;
      opacity: 0;
      position: absolute;
      top: $base-offset / 2;
      right: $base-offset / 2;
      font-size: 12px;
      line-height: 16px;
      height: auto;
      color: $darker-grey;
      fill: $red;
      padding: 0;
      width: 30px;

      & svg {
        width: 100%;
        height: 100%;
      }

      &:focus {
        outline: 0;
      }
    }

    &:hover {
      background: rgba($blue, .1);

      & .file-delete {
        opacity: 1;
        @include transition(.3s);
      }

      @include transition(.3s);
    }
  }

  & .roundButton {
    width: 150px;
    margin: $small-offset auto 0;
    text-align: center;
    @include tertiaryBtn()
  }
}
