.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}

.sidebar {
    position: absolute;
    top: 64px;
    left: 0;
    $full-width: $sidebar-width;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    float: left;
    height: calc(100vh - 65px);
    transition: .3s;
    width: 270px;
    overflow: hidden;
    max-width: 64px;
    z-index: 10;

    &.full {
        max-width: 9999px;
    }

    &-top {
        background-color: darken($dark, 5);

        &-logo {
            float: left;
            margin-left: $base-offset * 2 + 2px;
            font-size: 16px;
            height: 16px;

            color: #fff;
            text-transform: capitalize;
            line-height: 16px;
        }
    }

    &-config {
        margin-top: auto;
        cursor: pointer;
        transform: translateY(2px);

        &:hover {

            & .sidebar-config-list {
                opacity: 1;
                visibility: visible;
            }
        }

        & .sidebar-item-ico {

            & svg {
                max-height: 24px;
            }
        }
    }

    &-config:hover > &-config-list {
        transition: .3s;
        left: 80px;
        bottom: 5px;
    }

    &-config-list {
        opacity: 0;
        visibility: hidden;
        padding: 16px;
        position: fixed;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        box-sizing: border-box;
        box-shadow: 0px 8px 16px rgba(122, 127, 130, 0.08);
        border-radius: 2px;
        transition: .3ms;
        left: 80px;
        bottom: 5px;

        &::before {
            content: '';
            position: absolute;
            top: 12px;
            left: -41px;
            transform: rotate(15deg);
            height: 100%;
            width: 140px;
            cursor: auto;
        }
    }


    &-item, &-top, &-config {
        display: block;
        min-width: $full-width;
        @include clear();

        & .sidebar-item-ico {
            padding: 8px 0;
            min-width: 64px;
            max-width: 64px;
            height: 40px;
            display: flex;
            justify-content: center;
            color: black;
            @include transition(3ms);
        }

        & .fa {
            float: left;
            font-size: 16px;
            line-height: 16px;
            width: 16px;
            height: 16px;
            color: $grey;
            text-align: center;
        }

        &-label {
            font-family: $font-book;
            font-weight: 500;
            text-transform: capitalize;
            height: 16px;
            display: inline-block;
            font-size: 16px;
            line-height: 21px;
            color: #363839;
            margin-left: $base-offset + 2px;
            transition: 300ms;
        }

        & > a {
            display: flex;
            align-items: center;

            &.active {
                background-color: #ffffff;

                & svg {
                    fill: $blue;
                }

                & .sidebar-item-label {
                    color: $blue;
                }
            }
        }

        &:hover {
            transition: .5s;
            background-color: #ffffff;

            & svg {
                color: $blue;
            }
        }

        &.active {
            transition: .3s;
            background-color: #ffffff;

            & svg {
                color: $blue;
            }

            & .sidebar-item-label {
                color: $blue;
            }
        }
    }

    &-top {
        padding: $base-offset / 2 $base-offset + 2px;
    }

    &-item {
        font-family: $font-medium;
        position: relative;
        font-size: 16px;
        line-height: 21px;

        &:hover {
            border-radius: 1px;

            .sidebar__submenu {
                display: block;
            }

            .sidebar-item-label {
                color: $blue;
            }
        }

        & svg {
            width: 24px;
            @include transition(300ms)
        }
    }

    &__submenu {
        position: absolute;
        right: 0;
        top: 0;
        display: none;
        background-color: $dark;
        color: #fff;
        z-index: 10;
        transform: translateX(100%);
        max-width: 250px;

        &-item {
            padding: 0;
            margin: 0;
            list-style: none;

            .sidebar-item-label {
                margin-left: 0;
            }
        }

        li {
            padding: 8px 18px;

            &:hover {
                transition: .5s;
                background-color: rgba($blue, .3);
            }
        }
    }
}
