@import './form';
@import './button';
@import './contract-navigation';
@import './loader';
@import './react-transition';
@import './expenses-details-widget';
@import './dashboard';
@import './dialog';
@import './form-panel';
@import './form-popover';
@import './form-modal';
@import './autocomplete-input';
@import './context-menu';
@import './editable-field';
@import './file-gallery';
@import './file-uploader';
@import './comment-form';
@import './tag-list';
@import './accordeon';
@import './notification';
@import './categories';
@import './nested-table';
@import './assign-parts-form';
@import './activity-log';
@import './activity-log-support';
@import './opportunity-converter';
@import './hour-glass';
@import './summary-widget';
@import './cards';
@import "./period";

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(#fff, .8);
  z-index: 20;

  & .pt-intent-success {
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%, 0);
    z-index: 20;

    & .pt-spinner-svg-container {
      z-index: 2;
    }

    & .pt-spinner-track {
      stroke: $grey;
    }

    & .pt-spinner-head {
      stroke: $green !important;
    }
  }
}

.timer {
  &.red {
    color: $red;
  }

  &.green {
    color: $green;
  }

  &.orange {
    color: $orange;
  }

  &.blue {
    color: $blue;
  }
}

.pt-toast.pt-intent-success {
  background-color: $green;
}

.pt-toast.pt-intent-danger {
  background-color: $red;
}
