.header {
  position: relative;
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  padding-right: 24px;

  //&::before {
  //	content: '';
  //	display: block;
  //	position: absolute;
  //	left: 0;
  //	top: 0;
  //	height: 100%;
  //	width: 72px;
  //
  //	@include backgroundImage('beta.png');
  //	background-position: left center;
  //	background-size: contain;
  //}


  & .logo {

    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  &-title {
    float: left;
    font-family: $font-book;
    font-size: 24px;
    line-height: $icon-size + 4;
    text-transform: capitalize;
    white-space: nowrap;
    margin: 0 44px 0 0;
  }

  & .burger-menu {
    padding: 0 16px;
    cursor: pointer;

    & svg {
      min-width: 30px;
      max-width: 30px;
    }
  }

  &-right {
    margin: 0 (-$base-offset / 2) 0 48px;

    & > * {
      float: left;
      margin: 0 $base-offset / 2;
    }

    & .roundButton {
      font-size: 10px;
      line-height: $icon-size + 4;
      height: $icon-size + 4;
    }

    & .extension {
      $indicator-size: 22px;

      position: relative;
      padding: 0 ($indicator-size + $base-offset) 0 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;

        width: $indicator-size;
        height: $indicator-size;
        margin-top: -$indicator-size / 2;

        border-radius: 100%;

        background-color: $grey;
      }

      &.active {
        &::before {
          background-color: $green;
        }
      }

      & .ls-select {
        margin-bottom: 0;

        & .Select {
          border-radius: 20px;
          height: $icon-size + 4;
          box-shadow: 0 0 0 1px #E3E3E3;

          & .Select-control {
            height: $icon-size + 4;
            border-radius: 20px;
            border: 0;
          }

          & .Select-value {
            font-size: 14px;
            line-height: $icon-size + 4;
          }

          & .Select-placeholder {
            font-size: 14px;
            line-height: $icon-size + 4;
          }

          & .Select-input {
            height: $icon-size + 4;

            input {
              font-size: 14px;
              line-height: $icon-size + 4;
            }
          }
        }
      }
    }

    & .user {
      display: flex;
      align-items: center;

      & .user-info {
        display: flex;
        align-items: center;
        margin-left: 8px;
      }

      & .logout {
        width: 16px;
        height: 16px;
        margin-left: $small-offset;
      }

      p {
        margin: 0;
      }

      padding: 0 0 0 $icon-size + $base-offset;
      position: relative;

      &-avatar {

        position: absolute;
        left: 0;
        top: 0;

        &::before {
          content: '\f2be';
          color: $darker-grey;
          font-family: FontAwesome, 'sans-serif';
          font-size: $icon-size + 4;
          line-height: $icon-size + 4;
          width: $icon-size + 4;
          height: $icon-size + 4;
          text-align: center;
        }
      }

      &-info {
        font-family: $font-book;
        font-weight: 500;
        font-size: 14px;
        color: $dark;
        height: $icon-size + 4;
        line-height: $icon-size / 2 + 2;

        a {
          font-family: $font-book;
          font-size: 12px;
          color: $darker-grey;
        }
      }
    }

  }
}
