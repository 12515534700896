
.bp3-transition-container {
    z-index: 50;
}

.bp3-datepicker {
    z-index: 1;
}


.popover-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 45;
    display: flex;
    justify-content: center;
    align-items: center;

    &.pointer-none {
        width: auto;
        height: auto;
    }

    & .darken {
        background-color: rgba(#000000, .6);
        width: 100%;
        height: 100%;
        pointer-events: auto;
    }

    &.no-overlay {
        width: auto;
        height: auto;
        pointer-events: none;
    }
}

.popover {
    $arrow-size: 16px;
    position: fixed;
    background-color: #fff;
    opacity: 0;
    z-index: 46;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    pointer-events: auto;
    &.scrollable {
        overflow: scroll;
    }

    &::after {
        display: none;
        content: '';
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;

        border-width: $arrow-size;
        margin-left: -$arrow-size;
        z-index: 1;
    }

    & .ls-radiogroup-wrap {
        display: flex;
        justify-content: space-between;

        & > div {
            width: calc(100% / 2 - 12px);
            text-align: center;

            & label {
                width: 100%;
            }
        }
    }

    &.assign-part {


        & .checkbox-list {
            max-height: 40vh;
            overflow-y: scroll;
            width: auto;
            height: auto;
            position: relative;
            visibility: visible;
            display: flex;
            flex-direction: column;
            align-items: center;

            & .checkbox {
                visibility: visible;
                opacity: 1;
            }

            & svg {
                width: 24px;
                margin-right: 24px;
            }
        }
    }

    &.add-purchase {

        & .ls-form {
            display: block;
        }
    }

    &-head {
        padding: 16px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E6E6E6;

        & svg {
            fill: #363839;
            width: 24px;
            height: 24px;
        }

        & .close {
            transform: rotate(45deg);
            fill: #7A7F82;
        }


        & h3 {
            font-size: 18px;
            line-height: 24px;
            color: #282A2B;
            font-family: $font-medium;
            margin: 0;
        }

        & .controls {
            margin: 0 (-$base-offset / 4);

            & a {
                padding: 0 $base-offset / 4;
                float: left;
                display: block;
                font-size: 24px;
                line-height: 16px;
                color: #282A2B;

                &:hover {
                    @include transition(.3s);
                    color: lighten($grey, 10);
                }
            }
        }
    }

    & .form-popover-buttons {

        & button {
            @include tertiaryBtn();
        }
    }

    & .popover-content {
        padding: $small-offset;
        position: relative;

        & form.first-step_no-buttons {
            padding: 0;
        }

        .remove {
            cursor: pointer;
            position: absolute;
            left: 24px;
            bottom: 12px;
            width: calc(100% / 2 - 36px);
            z-index: 1;
            border: 1px solid $red;
            border-radius: 2px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $red;

            & svg {
                fill: $red;
                width: 20px;
                margin-right: 5px;
            }
        }

        @for $i from 1 through 10 {
            &.columncount-#{$i} {

                & > form {
                    display: grid;
                    grid-template-columns: repeat(#{$i}, minmax(100px, 1fr));
                    grid-column-gap: 24px;

                }
            }
        }

        & > form {
            padding-bottom: 56px;


            &.scrolled {
                max-height: 55vh;
                overflow-y: auto;
                padding-bottom: 0;
                margin: 0 -24px;

                & .scrolled-wrapper {
                    padding-bottom: 30px;
                }

                & .selectButton {
                    padding: 6px 24px;
                }
            }

            & .ls-textarea {
                grid-row-end: span 2;
            }

            &.customer-form {
                & > div:nth-last-child(2){
                    margin-bottom: 0;
                }
                & > .ls-textarea {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
            }

            &.opportunity-form {

                & > div:first-child {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    margin-bottom: 0;
                }
            }
        }


        & .ls-form {
            align-items: flex-start;
            flex-direction: column;

            input,
            textarea,
            & .select > div {
                border-color: #DDDDDD;
            }
        }

        & > .set-status {

            & label {
                display: none;
            }
        }
    }

    & .relative-buttons {
        position: absolute;
        left: 24px;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #E6E6E6;
        margin: 24px -24px 0;
        display: flex;
        padding: 12px 24px;
        justify-content: flex-end;

        & button.offset {
            margin-right: 23px;
        }

        & button {
            width: calc(100% / 2 - 12px);

            &:last-child {
                margin-left: auto;
            }
        }
    }

    &.visible {
        animation: showPopoverAnimation .4s .3s ease-in-out forwards;
    }

    &.BOTTOM_LEFT {
        transform: translate3d(-100%, $base-offset / 2, 0);
        // margin-top: $base-offset;
        &::after {
            display: none;
            right: 10%;
            top: -($arrow-size * 2 - 1);
            border-bottom-color: $blue;
        }
    }

    &.BOTTOM_RIGHT {
        right: 8px;
        left: auto !important;
        transform: translateY(-46%);
        height: calc(100vh - 70px);
    }

    &.TOP_LEFT {
        transform: translate3d(-100%, -100%, 0);

        &::after {
            display: none;
            right: 10%;
            bottom: -$arrow-size * 2 - 1;
            border-top-color: rgba($blue, .075);
        }
    }

    &.TOP_RIGHT {
        transform: translate3d(0, -100%, 0);

        &::after {
            display: none;
            left: 10%;
            bottom: -$arrow-size * 2 - 1;
            border-top-color: rgba($blue, .075);
        }
    }

    &.CENTER {
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);

        &::after {
            display: none;
        }
    }
    &.CENTER_LEFT {
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -100%, 0);

        &::after {
            display: none;
        }
    }

    &.CENTER_RIGHT {
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, 100%, 0);

        &::after {
            display: none;
        }
    }

    &.sd {
        width: 255px;
    }

    &.bd {
        width: 350px;
    }

    &.md {
        width: 500px;
    }

    &.ld {
        width: 750px;
    }

    &.close {
        animation: hidePopoverAnimation .3s ease-in-out forwards;
    }

    &.no-overflow {
        @include overflow(visible);

        & .popover-content {
            @include overflow(visible);
        }
    }

    @keyframes showPopoverAnimation {
        from {
            opacity: 0;
            margin-top: -40px;
        }
        to {
            opacity: 1;
            margin-top: 0;
        }
    }

    @keyframes hidePopoverAnimation {
        from {
            opacity: 1;
            margin-top: 0;
        }
        to {
            opacity: 0;
            margin-top: 40px;
        }
    }
}

.dropdown {
    position: relative;
    font-family: $font-semibold;
    font-size: 24px;
    padding-right: $small-offset !important;
    width: 70px;

    & svg {
        fill: #363839;
    }

    & > span:hover ~ .dropdown-helpers,
    .dropdown-helpers:hover {
        opacity: 1;
        visibility: visible;

    }

    & .dropdown-helpers {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        z-index: 9;
        top: 25px;
        right: 50px;
        display: flex;
        flex-direction: column;
        padding: $small-offset/2 0;
        background: #FFFFFF;
        border: 1px solid #EBEBEB;
        box-sizing: border-box;
        box-shadow: 0 8px 16px rgba(122, 127, 130, 0.08);
        border-radius: 2px;
        @include transition(300ms);

        & span {
            display: flex;
            align-items: center;
            font-family: $font-book;
            font-size: 16px;
            min-width: 88px;
            cursor: pointer;
            padding: $base-offset/2 $small-offset;
            @include transition(300ms);

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                background-color: rgba($blue, 0.08);
                color: $blue;

                & svg {
                    fill: $black;
                }
            }
        }

        & svg {
            fill: #363839;
            width: 15px;
            height: 15px;
            margin-right: 5px;
        }
    }
}
