.right-panel {
  position: relative;
  float: right;
  height: 100%;
  width: 260px;
  padding: $base-offset * 1.5 $base-offset * 1.5 $icon-size + $base-offset * 3 $base-offset * 1.5;
  background-color: #fff;

  &::-webkit-scrollbar {
    display: none;
  }

  h3 {
    font-family: $font-book;
    font-size: 22px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: $base-offset * 1.5;
    float: left;
  }

  animation: openAnimation ease-in-out .3s forwards;

  &.close {
    animation: closeAnimation ease-in-out .3s forwards;
  }
}
