
.messages {
    margin-left: auto;

    & .notification-list {
        overflow-y: scroll;
        max-height: 73vh;
        margin: 0 -24px;
        padding: 0 24px;
        position: relative;
    }

    & .new-messages {
        display: flex;
        background-color: #fff;
        padding: 6px 8px;
        border: 1px solid #EDEDED;
        border-radius: 16px;
        cursor: pointer;
        color: red;
        max-height: 35px;

        & span {
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            margin-left: 8px;
            margin-right: 2px;
        }

        & .messages-icon {
            max-width: 20px;
        }
    }

    &-icon {
        fill: #7A7F82;
        min-width: 16px;
        cursor: pointer;
        animation-iteration-count: infinite;
    }

    & .read-all {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        font-family: $font-medium;
        text-align: center;
        cursor: pointer;
        color: #005FCE;
        padding-bottom: 12px;
        padding-top: 12px;
    }

    &-item {
        margin: 0 -24px;
        padding: 16px 24px;
        border-bottom: 1px solid #E6E6E6;
        transition: 300ms;
        cursor: pointer;

        &:hover {
            background: rgba(0, 94, 184, 0.08);


            & .messages-item__remove {
                opacity: 1;
                visibility: visible;
            }
        }

        &__heading {
            display: flex;
            align-items: center;
            min-width: 260px;
            margin-right: auto;
        }

        &__controller {
            min-height: 26px;
            min-width: 86px;
            margin-left: auto;
            display: flex;
            align-items: center;
        }

        &__title {
            font-family: $font-medium;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #005FCE;
            margin-right: 5px;

        }

        &__action {
            font-size: 16px;
            margin-right: 30px;
        }

        &__remove {
            margin-left: auto;
            align-self: center;
            width: 24px;
            cursor: pointer;
            fill: #F06262;
            display: flex;
            align-items: center;
            opacity: 0;
            visibility: hidden;
            transition: 300ms;

            & svg {
                width: 16px;
            }
        }

        &__new {
            margin-left: 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #28CD56;
            padding: 3px 8px;
            border: 1px solid #28CD56;
            border-radius: 12px;
            position: relative;
            overflow: hidden;

            &-overlay {
                opacity: 0;
                transition: 300ms;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: #28CD56;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    opacity: 1;
                    color: #FFFFFF;
                }

                & svg {
                    width: 16px;
                    fill: #ffffff;
                }
            }
        }

        & .time-ago {
            margin-top: 5px;
            font-size: 12px;
            line-height: 16px;
            color: #808080;
        }
    }
}

.notification-select-btns {
    margin-bottom: 24px;

    button {
        display: inline-block;
        width: 30%;
        margin-right: 8px;
    }
}
