.base-layout {
    & .main-wrap {
        @include overflow();
        height: 100vh;


        & .content-container {
            padding-left: $sidebar-short-width;
            position: relative;
            @include overflow();
            height: 100%;
            width: 100%;

            & > * {
                position: relative;
            }
        }
    }
}

@media (max-width: 768px) {
    .base-layout {
        .main-wrap {
            overflow-y: scroll;

            .content-container {
                overflow-y: scroll;
            }
        }
    }
}
