.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  & .pt-intent-success {
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%, 0);
    z-index: 20;

    & .pt-spinner-svg-container {
      z-index: 2;
    }

    & .pt-spinner-track {
      stroke: $grey;
    }

    & .pt-spinner-head {
      stroke: $green !important;
    }
  }
}
