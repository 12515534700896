$hour-glass-size: 56px;

.hour-glass {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: $hour-glass-size;

  text-align: center;

  &__wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    & .fa {
      display: block;
      font-size: 20px;
    }

    & span {
      font-family: $font-book;
      font-weight: 600;
      float: left;
      line-height: 10px;
      margin-top: 8px;
    }
  }

  &--fit {
    width: 100%;
    height: 100%;
  }

  &--inline {
    display: inline-block;
    position: static;
    left: 0;
    top: 0;
    transform: none;

    & .hour-glass__wrap {
      display: block;
      position: static;
      left: 0;
      top: 0;
      transform: none;
    }

    & i, & span {
      float: left;
    }

    & span {
      margin: 0 4px 0 !important;
    }
  }

  &--noicon {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
  }

  &--sm {
    & .fa {
      font-size: 14px;
    }

    & span {
      line-height: 14px;
      font-size: 12px;
    }
  }

  &--green {
    & .fa {
      color: $green !important;
    }

    & span {
      color: $green !important;
    }
  }

  &--blue {
    & .fa {
      color: $blue !important;
    }

    & span {
      color: $blue !important;
    }
  }

  &--yellow {
    & .fa {
      color: $orange !important;
    }

    & span {
      color: $orange !important;
    }
  }

  &--red {
    & .fa {
      color: $red !important;
    }

    & span {
      color: $red !important;
    }
  }
}
