.support-grid {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
  column-gap: 1.5rem;
}

.activity-messages {
  padding: 10px;
  margin: 0 -1rem;
  &:empty {
    display: none;
  }
}
