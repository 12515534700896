.service-price {
  margin-bottom: 8px;

  .editable-field {
    width: 80%;
  }

  .remove-field-group {
    float: right;
    display: inline-block;
    font-weight: bold;
  }
}
