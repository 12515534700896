.summary-widget {
  padding: $base-offset;
  color: $blue;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  display: flex;
  align-items: center;


  &__icon {
    width: 55px;
    height: 55px;
    margin-right: $base-offset;
    padding: $base-offset/2;

    & svg {
      fill: $blue;
    }
  }

  &__value {
    font-family: $font-medium;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #282A2B;
  }

  &__label {
    font-family: $font-book;
    @include overflow();
    width: 100%;
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: #282A2B;
    margin-bottom: 8px;
  }
}
