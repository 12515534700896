.comment-form {
  $avatar-size: $base-offset * 2;
  position: relative;
  padding-left: $avatar-size + $base-offset * 1.5;
  margin-left: $base-offset * 2;

  & .avatar {
    display: block;
    width: $avatar-size;
    height: $avatar-size;
    line-height: $avatar-size;
    text-align: center;
    border: 1px solid $darker-grey;
    color: $darker-grey;
    border-radius: 100%;

    position: absolute;
    left: 0;
    top: 0;
  }

  & .ls-form {
    margin-bottom: $base-offset;

    & > label {
      display: none;
    }

    & textarea {
      @include transition(.3s);
      background: rgba($blue, .1);

      &:focus {
        @include transition(.3s);
        background: #fff;
      }
    }
  }
}
