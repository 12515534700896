@import "./normalize";
@import "./config";
@import "./mixins";
@import '~react-notifications-component/dist/theme.css';
@import "~animate.css/animate.min.css";


body {

    & .app {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    & .not-assigned {
        color: rgba($red, .3);
        font-family: $font-book;
    }

    color: $dark;
    background-color: $lighter-grey;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    min-width: 1280px;

    a {
        &:hover {
            text-decoration: none;
        }

        &:focus {
            outline: none;
        }
    }

    p {
        margin: 0;
        font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    }

    .blue-link-btn {
        color: #4288ca;
    }

    & .ls-form.address-form {

        & input {
            height: 38px;
        }
    }

    & .ls-form {
        display: flex;
        flex-direction: row;

        &.with-table {
            align-items: flex-start;

            & label {
                margin-top: 10px;
                width: 30%;

            }
        }

        & .input-field {
            width: 100%;
            position: relative;

            &:focus {
                & svg {
                    fill: $border-color;
                }
            }

            & .withico {
                padding-left: 32px;
            }

            & svg {
                width: 18px;
                height: 18px;
                margin-right: 7px;
                transition: 300ms;
                position: absolute;
                z-index: 1;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
            }

            & input {
                height: 38px;
                background: #fff;
                transition: 300ms;
                color: black;

                &:hover {
                    border: 1px solid rgba($blue, 0.16);
                }

                &:focus {
                    background: transparent;
                    border: 1px solid rgba($blue, 1);
                }
            }

            & .focus {
                fill: $border-color;
            }
        }

        & input {
            border-radius: 2px;
            @include transition(300ms);

            &:hover {
                border-color: rgba($blue, 0.3);
            }
        }

        &.multiselect {
            width: 100%;
            border-radius: 2px;

            & > div {
                width: 100%;
                border-color: rgba($border-color, 0.16);
                @include transition(300ms);
                border-radius: 2px;
                min-height: 32px;

                &:hover {
                    border-color: rgba($border-color, 1);
                }

                & input {
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        & .select {
            width: 100%;
            border-radius: 2px;

            & > div {
                border-color: transparent;
                @include transition(300ms);
                border-radius: 2px;

                &:hover {
                    border-color: rgba($border-color, 0.16);
                }

                &:focus {
                    border-color: rgba($border-color, 1);
                }

                & input {
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
            &.is-disabled {
                & .Select-placeholder {
                    color: red!important;
                }
                & > .Select-control {
                    background: red;
                    border-color: $lighter-grey;
                }
            }
        }

        &.ls-timeinput {
            & .timeinput-wrapper {
                margin-top: 10px;
                display: grid;
                grid-template-columns: 100px 4px 100px;
                grid-column-gap: 12px;
            }
            & .notice {
                margin-top: -5px;
                font-size: 12px;
                color: $grey;
            }
        }

        &.ls-dateinput {

            & .bp3-disabled {
                cursor: default;
                & input[disabled] {
                    color: #808080;
                    background-color: #EBEBEB;
                    border: 1px solid #DDDDDD;
                    opacity: .6;
                    cursor: default;
                }
            }
        }

        &.ls-autocomplete {
            & input[disabled] {
                color: #808080;
                background-color: #EBEBEB;
                border: 1px solid #DDDDDD;
                opacity: .6;
            }
        }

        &.ls-textinput, &.ls-textarea {
            & textarea[disabled] {
                color: #808080;
                background: #EBEBEB;
                border: 1px solid #DDDDDD;
                opacity: .6;
            }
        }

        .popover &,
        .modal & {
            flex-direction: column;
        }
    }

    .ls-picker {


        &__list {
            width: 100%;
            display: flex;
            justify-content: space-between;

            & > div {
                width: calc(100% / 2 - 5px);
                cursor: pointer;
                font-size: 16px;
                border: 1px solid #DDDDDD;
                transition: 300ms;
                color: black;
                font-family: $font-light;

                &.tertiaryBtn {
                    border: 1px solid rgba(0, 95, 206, 0.16);
                    color: #ffffff;
                    font-family: $font-book;

                    &:hover {
                        color: #ffffff;
                        border: 1px solid transparent;
                    }
                }
            }
        }
    }

    & .secondaryBtn {
        @include secondaryBtn();

        &:focus {
            outline: 0 !important;
            box-shadow: unset !important;
        }
    }

    & .tertiaryBtn {
        @include tertiaryBtn();
    }

    #part-details-form {
        .range-divider {
            margin: 0 20px;
            height: 35px;
        }
    }
}

.dialog-wrapper {

    & .ls-form {
        flex-direction: column;
    }
}

.bg-grey {
    background-color: #eeeeee;
    color: #000000 !important;
}

.bg-cyan {
    background-color: #72E7EE;
}

.bg-purple {
    background-color: #C672EE;
}

.bg-orange {
    background-color: #EEA460;
}

.bg-green {
    background: #28CD56;
}

.bg-red {
    background-color: #F06262;
}

.svg {
    min-width: 16px;
}

.wysivyg-redactor-canvas {
    min-height: 200px;
    border: 1px solid #F1F1F1;
    border-top: 0;
    margin-top: -6px;
    max-height: 40vh;
    padding: 0 12px;
}

.violationMessage {
    top: 50%;
    right: -10px;
    transform: translateX(100%) translateY(-50%);
    position: absolute;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(122, 127, 130, 0.08);
    border: 1px solid $red;
    border-radius: 2px;
    padding: 8px 12px;
    max-width: 190px;
    //z-index: 1;
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -8px;
        width: 15px;
        height: 15px;
        background: #ffffff;
        border: 1px solid #df5260;
        border-bottom: 0;
        border-right: 0;
        border-radius: 2px;
        transform: translateY(-50%) rotate(-45deg);
    }
}

.switch-toggler {

    form & {
        margin-bottom: 24px;
    }

    & label {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    & svg {
        width: 24px;
        @include transition(300ms);

        &.checked {
            fill: $blue;
        }
    }

    & input {
        display: none;
    }
}

//LIBS
@import "~@blueprintjs/core/src/blueprint";
@import "~@blueprintjs/datetime/src/blueprint-datetime";
@import "~font-awesome/css/font-awesome.css";

//BASE
@import "base/header";
@import "./base/sidebar";

// //CONTAINERS
// @import "./containers/right-panel";
// @import "./containers/modal";

//LAYOUTS
@import "./layouts/base";
@import "./layouts/right-panel";
@import "./layouts/popover";
@import "./layouts/tabs";

//COMPONENTS
@import "./components/components";

// //CONTENT
@import "./content/login";
@import "./content/tickets";
@import "./content/settings";
@import "./content/center-form";
@import "./content/filters-panel";
@import "./content/entity-details";
@import "./content/product-grid";
@import "./content/segments";
@import "./content/generic-entity-details-page";
@import "./content/purchases";
@import "./content/contract-profit";
@import "./content/opportunity";
@import "./content/part-realizations/part-realization-modal";
@import "./content/part-realizations/part-realizations-manager.scss";


// MEDIA QUERIES
@import "./media";

// @import "./content/dashboard";
// @import "./content/statistics";
// @import "./content/report-builder"
