.bp3-popover-target {
  width: 100%;
}

.form-popover {
  & .bp3-popover-target {
    width: 100%;
  }

  & .ls-form {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: $base-offset;

    & .bp3-popover-target {
      width: 100%;
    }

    & label {
      white-space: nowrap;
      margin-right: 15px;
      max-width: 200px;
    }

    & input,
    & .select > div {
      border: 1px solid transparent;

      &:hover {
        border: 1px solid rgba($blue, .4)
      }

      &:focus {
        border: 1px solid rgba($blue, 1)
      }
    }


    & .Select-control {
      padding: 0 !important;
      height: $base-offset * 2 !important;
      width: 100% !important;
    }

    & .Select-value, & .Select-placeholder {
      line-height: 18px !important;
    }

    & .pt-timepicker {
      display: block;
      text-align: center;
    }
  }

  & button {
    width: 100%;
  }

  & .autocomplete-input {
    margin-bottom: $base-offset * 2;
  }
}

.part-realization-form {
  & .section {
    & label {
      display: none;
    }

    & .ls-form {
      margin-bottom: $base-offset / 2;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    margin-bottom: $base-offset;
    padding-top: $base-offset;
    border-top: 1px solid $darker-grey;
  }

  & .link {
    float: right;
    color: $darker-grey;
    font-size: 14px;
    margin-left: $base-offset;

    &:last-of-type {
      margin-left: 0;
    }

    & .fa {
      margin-right: $base-offset / 2;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.part-realization-list {
  max-height: 400px;
  @include overflow(hidden, scroll);
  margin: 0 (-$base-offset * 1.5);

  & ul {
    padding: 0;
    margin: 0;
    list-style: none;

    & li {
      padding: $base-offset $base-offset * 1.5;
      position: relative;

      &:nth-child(2n) {
        background-color: rgba($grey, .15);
        // color: #fff;
      }

      & .list-index {
        float: left;
        margin-right: $base-offset;
      }

      & .list-title {
        font-family: $font-book;
        font-weight: 500;

        & a {
          float: right;
          display: block;
        }
      }

      & .list-count {
        opacity: 0;
        display: none;
        position: absolute;
        top: -$base-offset / 2;
        right: $base-offset * 2;
        line-height: $icon-size;

        & > * {
          display: inline-block;
          font-size: 16px;
          line-height: $icon-size;
          color: $darker-grey;
        }

        & input {
          padding: 0;
          border: 0;
          background: none;
          width: 40px;
          text-align: center;
          color: black;
        }

        & a {
          margin: 4px;
        }
      }

      & .list-add-fields {
        font-size: 12px;
        font-family: $font-book;
        color: $darker-grey;

        & span {
          float: left;
          margin-right: $base-offset / 2;
          font-family: $font-book;
          font-weight: 500;
        }
      }

      & .list-notes {
        font-size: 12px;
        font-style: italic;
        color: $darker-grey;
      }

      & .list-title, & .list-notes {
        margin-bottom: $base-offset / 2;
      }

      & .list-info {
        @include overflow();
      }

      & .list-type {
        float: left;
        text-transform: capitalize;
        color: $grey;
      }

      & .list-updated, & .list-add-serial {
        float: right;
        color: $grey;
      }

      & .list-add-serial {
        opacity: 0;
        color: $darker-grey;
      }

      & .list-item-wrap {
        position: relative;
        @include overflow();

        &:hover {
          & .make-faulty-btn {
            opacity: .8;
            @include transition(.3s);
          }
        }
      }

      & .make-faulty-btn {
        opacity: 0;
        color: $red;
        font-size: 14px;
        position: absolute;
        top: 0;
        right: 0;

        &:hover {
          opacity: 1;
          @include transition(.3s);
          text-decoration: underline;
        }
      }

      & .list-price {
        color: $darker-grey;

        &__label {
          font-size: 14px;
          line-height: 16px;
          margin-right: $base-offset / 2;
          float: left;
        }

        &__cost {
          font-size: 14px;
          line-height: 16px;
          color: $green;
          font-family: $font-book;
          font-weight: 500;
          letter-spacing: .01em;

          &__currency {
            float: left;
          }

          & input {
            color: $green;
            font-family: $font-book;
            font-weight: 500;
            background: none;
            border: 0;
            padding: 0;
            width: auto;
            line-height: 16px;
            height: 16px;
            letter-spacing: .01em;

            &:hover {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

.part-realization-move-form {
  max-height: 600px;
  @include overflow(hidden, scroll);
  margin: 0 (-$base-offset * 1.5);
  padding: 0 $base-offset * 1.5;

  & .part-realization-list {
    margin-bottom: $base-offset * 1.5;

    & li {
      position: relative;

      & .toggle-btn {
        z-index: 1;
        display: block;
        height: 16px;
        width: 16px;

        position: absolute;
        top: $base-offset + 8px;
        right: $base-offset / 2 + 8px;
        transform: translate3d(-50%, -50%, 0);

        &::after {
          content: '\f096';
          font-family: FontAwesome;
          color: $grey;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          display: block;
          height: 16px;
          width: 16px;
        }

        &:hover {
          right: $base-offset / 2 + 7px;

          &::after {
            content: '\f046';
          }
        }
      }

      & .input-wrap {
        position: relative;
        margin: $base-offset / 2 $base-offset * 2 0;

        & .ls-form {
          position: relative;
          margin: 0;

          &::before {
            content: "\f02a";
            font-family: FontAwesome;
            font-size: 16px;
            position: absolute;
            top: 50%;
            left: -$icon-size;
            color: $darker-grey;
            transform: translate3d(0, -50%, 0);
          }
        }

        & .delete-serial {
          position: absolute;
          font-size: 16px;
          color: $darker-grey;
          top: 50%;
          right: -30px;
          transform: translateY(-50%);

          &:hover {
            color: $red;
            @include transition(.3s);
          }
        }
      }

      &.selected {
        & .toggle-btn {
          right: $base-offset / 2 + 7px;

          &::after {
            color: $green;
            content: '\f046';
          }
        }

        & .list-count, & .list-add-serial {
          opacity: 1;
          display: block;
          @include transition(.3s);
        }
      }
    }
  }
}

.expense-list {
  font-size: 16px;
  margin: -$base-offset * 1.5;
  padding: 0;
  list-style: none;

  &__item {
    position: relative;
    padding: $base-offset * 1.5;
    font-size: 16px;
    line-height: 16px;

    &:nth-child(2n) {
      background-color: rgba($grey, .15);
    }

    &__title {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: $base-offset / 2;
    }

    &__time {
      position: absolute;
      top: $base-offset * 1.5;
      right: $base-offset * 1.5;
      font-size: 12px;
      color: $grey;
    }

    &__notes {
      color: $darker-grey;
      font-size: 12px;
      margin-bottom: $base-offset / 2;
    }

    &__cost {
      font-size: 24px;
      line-height: 24px;
      color: $green;
      font-family: $font-book;
      font-weight: 500;
      letter-spacing: .01em;

      & input {
        font-family: $font-book;
        color: black;
        font-weight: 500;
        background: none;
        border: 0;
        padding: 0;
        /* display: inline-block; */
        width: auto;
        line-height: 24px;
        height: 24px;
        letter-spacing: .01em;

        &:hover {
          box-shadow: none;
        }
      }
    }

    &__buttons {
      opacity: 0;
      position: absolute;
      right: $base-offset * 1.5;
      bottom: $base-offset * 1.5;

      & a {
        font-size: 16px;
        line-height: 24px;
        height: 24px;
        color: $darker-grey;

        float: left;
        display: block;
        margin-right: $base-offset / 2;

        &:last-of-type {
          margin: 0;
        }
      }
    }

    &:hover {
      .expense-list__item__buttons {
        @include transition(.3s);
        opacity: 1;
      }
    }
  }
}

.entity-info {
  &__row {
    opacity: 0;
    animation: appear .3s ease-in-out forwards;
    font-size: 18px;
    margin-bottom: 24px;

    & label {
      float: left;
      width: 120px;
      margin-right: 16px;
      font-family: $font-book;
      font-weight: 600;
    }

    & button {
      background-color: transparent;
      padding: 0;
      border: 0;
      color: #106ba3;
    }

    & p {
      @include overflow();
      color: $darker-grey;
      display: block;

      & .not-set {
        color: rgba($red, .5);
      }
    }

    &__parts {
      @include overflow();
    }

    &__part {
      padding-bottom: $base-offset / 2;
      margin-bottom: $base-offset / 2;
      border-bottom: 1px dashed $grey;

      &:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }

      &__title {
        font-weight: 600;
      }

      &__serial {
      }

      &__cost {
        font-family: $font-book;
        font-weight: 600;
        font-size: 14px;
        color: $green !important;
      }
    }
  }


  &.sale-info {
    $row-height: 32px;

    & .sale-info__delivery-row {
      margin-top: $base-offset;
      padding-top: $base-offset;
      border-top: 1px solid $grey;

      & .sale-info__delivery-row__skeleton {
        height: $row-height;
      }

      & .sale-info__delivery-row__price {
        @include transition(.3s);
        animation: appear .3s ease-in-out forwards;

        & label {
          font-size: 16px;
          font-weight: 600;
          display: block;
          line-height: $row-height;
          height: $row-height;
          float: left;
        }

        & .sale-info__delivery-row__price__input {
          float: right;

          & span {
            font-size: 32px;
            display: block;
            line-height: $row-height;
            height: $row-height;
            float: left;
            transform: translateY(-2px);
            color: $green;
          }

          & input {
            color: black;
            font-size: 32px;
            line-height: $row-height;
            height: $row-height;
            border: 0;
            box-shadow: none;
            padding: 0 4px;
            float: left;
            width: auto;

            max-width: 100px;
            background-color: rgba(256, 256, 256, .5);
            font-weight: bold;

            color: $green;
          }
        }
      }
    }
  }

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
