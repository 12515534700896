.categories {
    $min-width: 250px;
    position: relative;
    min-width: $min-width;
    height: 100%;
    padding: $base-offset 0 $base-offset * 2;
    background: #ffffff;

    &-name {
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @include overflow(hidden, scroll);

    &-title {
        font-family: $font-medium;
        font-size: 24px;
        line-height: 31px;
        color: #282A2B;
        margin-right: 48px;
    }

    & button {
        @include tertiaryBtn();
        @include transition(300ms);
    }

    &.drag-over {
        box-shadow: inset 0 0 0 1px rgba($green, .5);
    }

    & .categories-list-heading {
        display: flex;
        align-items: center;
        padding: 0 24px;
        margin-bottom: 24px;
    }

    & .categories-list-wrap {
        min-height: 100%;
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: -$base-offset;
            bottom: -$base-offset * 2;
            width: 15px;
        }

        & > ul {
            padding-bottom: 35px;
            padding-left: 14px;

            & > li {
                padding-left: 17px;
            }
        }
    }

    & ul {
        list-style: none;
        padding: 0;
        margin: 0;

        & li {

            & > .category-row {
                position: relative;
                height: 40px;
                cursor: pointer;
                display: flex;
                align-items: center;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    transform: translateX(-50%);
                    height: 100%;
                    width: 100vw;
                    background-color: transparent;
                    @include transition(300ms);
                }

                &:hover {

                    &::before {
                        background-color: rgba($blue, .06);
                    }

                    & .controls {
                        opacity: 1;
                    }
                }

                &.active {

                    &::before {
                        background-color: rgba($blue, .06);
                    }
                }

                & > .category-content-wrap {
                    display: flex;
                    align-items: center;
                }

                & .list-dot {
                    position: relative;
                    z-index: 1;
                    left: -10px;
                    width: 13px;
                    height: 13px;
                    border: 1px solid #C4C4C4;
                    border-radius: 50%;
                    background-color: #fff;
                }

                & > input {
                    padding: $base-offset / 2 36px $base-offset / 4 $base-offset;
                    border: 0;
                    line-height: 16px;
                    color: $blue;
                    font-family: $font-book;
                    font-size: 14px;
                    background: none;
                    width: 100%;

                    &:hover {
                        box-shadow: none;
                    }
                }

                & > .controls {
                    position: absolute;
                    top: 50%;
                    right: 16px;
                    transform: translateY(-50%);
                    opacity: 0;
                    z-index: 1;
                    display: flex;
                    align-items: center;

                    & span {
                        display: flex;
                        align-items: center;
                        margin-left: 5px;

                        &:hover {

                            & svg {
                                fill: $blue;
                            }
                        }
                    }

                    & svg {
                        width: 20px;
                        height: 17px;
                    }
                }
            }
        }

        [class*=step-] {
            position: relative;

            &::before {
                content: '';
                height: 100%;
                width: 1px;
                position: absolute;
                left: 10px;
                top: -19px;
                background-color: #C4C4C4;
            }

            & li {
                position: relative;

                &::before {
                    content: '';
                    background-color: #C4C4C4;
                    position: absolute;
                    left: 10px;
                    top: 20px;
                    height: 1px;
                    width: 14px;
                }
            }
        }

        @for $i from 1 through 10 {
            .step-#{$i} {
                padding-left: 16px;

                &::before {
                    left: -4px;
                }

                & li::before {
                    left: -20px;
                }
            }
        }

        .last {

            &::after {
                content: '';
                height: 100%;
                width: 5px;
                background-color: #fff;
                position: absolute;
                left: -30px;
                top: 40px;
            }

            & .decorate-block {
                width: 7px;
                height: 39px;
                background-color: #fff;
                position: absolute;
                z-index: 2;
                left: -28px;
                bottom: 39px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    border-left: 1px solid #C4C4C4;
                    border-bottom: 1px solid #C4C4C4;
                    height: 100%;
                    width: 100%;
                    border-bottom-left-radius: 5px;
                }
            }
        }
    }
}
