.entity-details {
  $action-bar-width: 240px;
  height: 100%;
  position: relative;
  background-color: $lighter-grey;

  &-form {
    .pt-popover-target {
      display: inline;
      position: static;
      vertical-align: top;
    }

    .context-menu {
      position: absolute;
      display: block;
      right: $base-offset;
      top: $base-offset * 1.5;
      z-index: 1;
      font-size: 24px;
      line-height: 24px;
      color: $grey;

      width: $base-offset * 1.5;
      height: $base-offset * 1.5;
      text-align: center;

      &:hover {
        color: $blue;
        @include transition(.3s);
      }
    }
  }

  &.ticket {
    & .comment-form {
      margin-bottom: $base-offset * 2;
    }
  }

  &.serial-numbers {
    .side {
      max-height: 400px;
      overflow-y: scroll;

      .serial-numbers-list {
        margin-top: 20px;

        p {
          margin-bottom: 4px;

          a {
            float: right;
            display: inline-block;
          }
        }
      }
    }
  }

  & .main {
    max-height: 80vh;
    padding: $base-offset * 2 $base-offset * 2.5;
    @include overflow(scroll, hidden);
  }

  & .main {
    padding: $base-offset * 2 $action-bar-width $base-offset * 2 $base-offset * 2.5;
    position: relative;
    @include overflow(hidden, scroll);

    h3 {
      font-family: $font-medium;
      font-size: 16px;
      color: $dark;
      display: block;
      margin-bottom: $base-offset;

      & .fa {
        margin-right: $base-offset / 2;
      }
    }

    .section-block {
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
      padding: $base-offset * 2;
      margin-bottom: $base-offset * 1.5;

      &:last-of-type {
        margin-bottom: 0;
        // margin-bottom: $base-offset * 2;
      }
    }

    & > a {
      font-family: $font-book;
      font-size: 16px;
      color: $darker-grey;

      &:hover {
        text-decoration: underline;
      }
    }

    & .form-window-buttons {
      position: fixed;
      right: $base-offset * 2.5;
      bottom: $base-offset * 2;
      width: $action-bar-width - $base-offset * 5;
      z-index: 1;

      & button {
        width: 100%;
        margin-bottom: $base-offset;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    & .top-info {
      margin-bottom: $base-offset;

      & .time, & .status {
        font-family: $font-book;
        font-size: 12px;
        line-height: 12px;
        color: $darker-grey;

        & .fa {
          margin-right: $base-offset / 2;
        }
      }

      & .time {

        float: left;
      }

      & .status {
        float: right;
        position: relative;

        & > span {
          margin-left: $base-offset / 4;
          padding-right: $base-offset * .75;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;

            width: $base-offset / 2;
            height: $base-offset / 2;
            line-height: 12px;
            border-radius: 100%;
            border: 1px solid #fff;
          }
        }

        &.complete, &.converted {
          & > span {
            color: $green;

            &::after {
              background-color: $green;
              border-color: $green;
            }
          }
        }

        &.floating, &.pristine {
          & > span {
            color: $orange;

            &::after {
              background-color: $orange;
              border-color: $orange;
            }
          }
        }

        &.hold {
          & > span {
            color: $blue;

            &::after {
              background-color: #fff;
              border-color: $blue;
            }
          }
        }

        &.assigned {
          & > span {
            color: $orange;

            &::after {
              background-color: #fff;
              border-color: $orange;
            }
          }
        }

        &.inProgress, &.quoted {
          & > span {
            color: $blue;

            &::after {
              background-color: $blue;
              border-color: $blue;
            }
          }
        }

        &.failed, &.refused {
          & > span {
            color: $red;

            &::after {
              background-color: $red;
              border-color: $red;
            }
          }
        }
      }
    }

    & .entity-details-form {
      & .field-group {
        margin-bottom: $base-offset * 2;

        &:last-of-type {
          margin-bottom: 0;
        }

        & > .editable-field {
          // margin-bottom: $base-offset;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    & .file-add, & .right-link {
      margin-top: $base-offset;

      & a {
        float: right;
        color: $darker-grey;
        font-size: 14px;
        margin-left: $base-offset;

        &:last-of-type {
          margin-left: 0;
        }

        & .fa {
          margin-right: $base-offset / 2;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &.blue {
        & a {
          color: $blue;
        }
      }
    }

    & .edit-entity {
      margin: 0;
      position: absolute;
      top: calc(32px + 16px);
      right: calc(40px + 16px);
      box-shadow: -2px 2px 4px 0 rgba($darker-grey, .5);
    }

    & .custom-field {
      width: 100%;
      $label-width: 115px;
      margin: $base-offset / 4 0;
      min-height: $base-offset * 2;

      position: relative;
      padding-left: $label-width;

      & > label {
        position: absolute;
        top: 0;
        left: 0;
        width: $label-width;

        font-family: $font-book;
        font-weight: 500;
        font-size: 12px;
        color: $darker-grey;
        float: left;
        text-transform: capitalize;
        width: $label-width;
        line-height: $base-offset * 2;
        height: $base-offset * 2;
      }
    }

    & .accordeon {
      $nestedLevelsCount: 3;
      $offset: $base-offset * 2;
      margin-bottom: $base-offset;

      @for $i from 0 through $nestedLevelsCount {
        &.offset-#{$i} {
          margin-left: $offset * $i;
        }
      }

      & .accordeon-title-category {
        &::before {
          content: '';
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          height: 100%;
          width: $base-offset;
        }

        &.customer {
          &::before {
            background-color: $red;
          }
        }

        &.contract {
          &::before {
            background-color: $green;
          }
        }

        &.system {
          &::before {
            background-color: $orange;
          }
        }

        &.part {
          &::before {
            background-color: $blue;
          }
        }
      }
    }

    & .info-list {
      margin-top: $base-offset / 2;
      margin-bottom: $base-offset * 2;
      color: $darker-grey;
      font-family: $font-book;
      font-size: 12px;

      & label {
        float: left;
        margin-right: $base-offset / 2;

        & .fa {
          width: 16px;
          text-align: center;
        }
      }

      & .row {
        float: left;
        // white-space: nowrap;
        width: 100%;
        // height: $base-offset;
        & > * {
          display: inline-block;
          margin-bottom: $base-offset / 2;
        }

        &:last-of-type {
          & > * {
            margin-bottom: 0;
          }
        }
      }

      &__content {
        @include overflow();

        & > * {
          display: inline-block;
        }
      }

      a {
        display: inline-block;
        color: $blue;
        margin-left: $base-offset / 4;
        text-decoration: underline;
      }

      span {
        color: $blue;
        margin-left: $base-offset / 4;
        // text-decoration: underline;
      }
    }
  }

  & .side {
    max-height: 80vh;
    padding: $base-offset * 2 $base-offset * 2.5;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: $action-bar-width;

    height: 100%;
    @include overflow();

    &-delimeter {
      display: block;
      height: 2px;
      background-color: $grey;
      margin: $base-offset;
      border-radius: 2px;
    }
  }

  & .actions {
    &-title {
      font-family: $font-book;
      font-size: 20px;
      color: $dark;
      margin: 0 0 $base-offset;
      line-height: 24px;
    }

    & button {
      width: 100%;
      margin-bottom: $base-offset / 2;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &.no-actionbar {
    & .main {
      padding: $base-offset * 2 $base-offset * 2.5;
    }
  }

  .button-transition-enter {
    display: block;
    @include overflow();
    height: 0;
  }

  .button-transition-enter.button-transition-enter-active {
    $wrap-destination-height: 36px + $base-offset;
    height: $wrap-destination-height;
    @include transition(.3s);
  }

  .button-transition-leave {
    display: block;
    $wrap-destination-height: 36px + $base-offset;
    height: $wrap-destination-height;
    @include overflow();
  }

  .button-transition-leave.button-transition-leave-active {
    height: 0;
    @include transition(.3s);
  }

  .add-part-btn-wrap {
    @include overflow();
  }

  .add-part-btn {
    width: calc(100% - #{($base-offset * 2 * 3)});
    background: none;
    border: 0;
    border-radius: 4px;
    padding: $base-offset / 2 $base-offset * 2;
    display: block;
    font-family: $font-book;
    font-size: 16px;
    color: $blue;
    background-color: #fff;
    margin-bottom: $base-offset;
    margin-left: $base-offset * 2 * 3;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    @include transition(.3s);

    & .fa {
      margin-right: $base-offset / 2;
    }

    &:active, &:focus {
      outline: none;
    }

    &:active {
      box-shadow: 0 0px 0px 0 rgba(0, 0, 0, .1);
      @include transition(.1s);
    }
  }
}

.part-form, .product-form {

  position: relative;

  &-delete {
    position: absolute;
    display: block;
    right: 0;
    top: 4px;
    z-index: 1;
    font-size: 24px;
    color: $grey;

    &:hover {
      color: $red;
      @include transition(.3s);
    }
  }

  & .tabs {
    margin-top: $base-offset * 1.5;
  }

  & .compatibles {
    & .autocomplete-input {
      margin-bottom: $base-offset;
    }
  }
}

.exceeded-hour {
  margin-bottom: 24px;

  .toggle-btn {
    margin-top: 7px;
  }

  span.toggle-btn:hover::after {
    content: '\f096';
  }

  span.toggle-btn.selected:hover::after {
    content: '\f046';
  }

  .info {
    font-size: 12px;
  }
}
