@mixin primaryBtn() {
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    border-radius: 2px;
    border: 2px solid transparent;
    color: #005FCE;
    padding: 4px 12px;
    background: #ffffff;
    @include transition(300ms);

    & svg {
        fill: #005FCE;
        min-width: 16px;
        @include transition(300ms)
    }

    &:hover {
        background: rgba(0, 95, 206, 0.08);
        color: $blue;
    }

    &:focus {
        border: 2px solid rgba(0, 95, 206, 0.16);
    }

    &:active {
        background: rgba(0, 95, 206, 0.16);
    }

    &[disabled] {
        color: #808080;
        background: #EBEBEB;
    }
}

@mixin secondaryBtn() {
    outline: 2px solid transparent;
    border: 1px solid rgba(0, 95, 206, 0.16);
    background: #ffffff;
    border-radius: 2px;
    font-size: 16px;
    line-height: 21px;
    color: $blue;
    text-align: center;
    padding: 4px 12px;
    box-shadow: unset;

    & svg {
        fill: #282A2B;
        min-width: 16px;
        @include transition(300ms)
    }

    &:hover {
        border: 1px solid $blue;
        color: #005FCE;
        background: rgba(0, 95, 206, 0.16);

        & svg {
            fill: #005FCE;
        }
    }

    &:focus {
        color: #282A2B;
        background: #ffffff;
        border: 1px solid #DDDDDD;
        outline: 2px solid rgba(0, 95, 206, 0.16);
        box-shadow: $shadow-focus;
    }

    &:active {
        border: 1px solid #005FCE;
        background: rgba(0, 95, 206, 0.16);
        box-shadow: unset;
        color: #005FCE;
    }

    &.active {
        border: 2px solid rgba(0, 95, 206, 0.16);
        background: rgba(0, 95, 206, 0.16);
    }
    &[disabled] {
        opacity: .4;
    }
}

@mixin tertiaryBtn() {
    outline: 0;
    border: 2px solid transparent;
    background: #005FCE;
    border-radius: 2px;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    text-align: center;
    padding: 4px 12px;

    &.red {
        background-color: $red;

        &:hover {
            background-color: $red;
        }
    }

    & svg {
        fill: #ffffff;
    }

    &:hover {
        background: #2685F4;
        border-radius: 2px;
    }

    &:active {
        outline: 0;
        background: #005FCE;
        border-radius: 2px;
        border: 2px solid rgba(0, 95, 206, 0.16);
    }

    &:focus {
        background: #005FCE;
        border-radius: 2px;
        box-shadow: $shadow-focus;
    }

    &.active {
        border: 2px solid rgba(0, 95, 206, 0.16);
        background: #2685F4
    }
    &[disabled] {
        background: #005FCE;
        opacity: .4;
    }
}

@mixin primaryInput() {

}

@mixin clear() {
    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

@mixin overflow($x: hidden, $y: $x) {
    overflow-x: $x;
    overflow-y: $y;
}

@mixin rotate($angle) {
    -webkit-transform: rotate($angle);
    -moz-transform: rotate($angle);
    -o-transform: rotate($angle);
    transform: rotate($angle);
}

@mixin translate($x: 0, $y: 0, $z: 0) {
    -webkit-transform: translate3d($x, $y, $z);
    -moz-transform: translate3d($x, $y, $z);
    -o-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}

@mixin transition($time, $function: '') {
    -webkit-transition: $time;
    -moz-transition: $time;
    -o-transition: $time;
    transition: $time;
}

@mixin placeholderColor($color) {
    &::-webkit-input-placeholder {
        color: $color;
    }
    &::-moz-placeholder {
        color: $color;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
        color: $color;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
        color: $color;
    }
}

//GRID GENERATOR
@mixin grid-generator($max-cells: 12) {

    //@include clear();

    @if $max-cells == 12 {
        .custom-grid {

            &.base-offset {
                margin: 0 (-$base-offset / 2);

                & > * {
                    padding: 0 $base-offset / 2;
                }
            }
        }
    } @else {
        .custom-grid-#{$max-cells} {
            margin: 0 (-$base-offset / 4);

            @for $i from 1 through $max-cells {
                .cell-#{$i} {
                    float: left;
                    padding: 0 $base-offset / 4;
                }
            }

            &.base-offset {
                margin: 0 (-$base-offset / 2);

                & > * {
                    padding: 0 $base-offset / 2;
                }
            }
        }
    }

}

@include grid-generator();
@include grid-generator(10);
