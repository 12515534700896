.generic-entity-details-page {
  height: 100%;

  & .top {
    position: relative;
    z-index: 2;
    box-shadow: -1px 2px 4px rgba($dark, .2);
    border-radius: 4px 4px 0 0;
    // @include overflow();

    & .left-part {
      position: relative;
      min-width: 20%;
      float: left;
      margin-right: $base-offset / 2;
      background-color: #fff;
      padding: $base-offset $base-offset $base-offset $base-offset * 3;
      border-radius: 4px 0 0 0;

      & .company-name {
        float: left;
        font-family: $font-book;
        font-size: 22px;
        line-height: $base-offset * 2;
      }

      & button {
        float: right;
        margin-left: $base-offset;
      }
    }

    & .main-part {
      position: relative;
      @include overflow();
      background-color: #fff;
      border-radius: 4px 0 0 0;
      padding: $base-offset / 2 $base-offset / 2 $base-offset / 2 $base-offset * 3;
      margin-right: calc(100px + #{$base-offset} * 2.5);

      & .back-button {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: $base-offset * 2;
        text-align: center;
        padding: $base-offset 0;
        line-height: $base-offset * 2;
        font-size: 32px;
        background-color: $blue;
        color: #fff;

        border-radius: 4px 0 0 0;
      }

      & .photo {
        $photo-size: $base-offset * 3;
        width: $photo-size;
        height: $photo-size;
        float: left;
        background-color: $darker-grey;
        border-radius: 4px;
        margin-right: $base-offset / 2;

        &::before {
          content: '';
          // content: '\f007';
          // font-family: FontAwesome;
          // font-size: $base-offset * 2;
          // line-height: $photo-size;
          display: block;

          height: $photo-size;
          width: $photo-size;
          text-align: center;
          color: #fff;
          background-image: url('./../../media/avatar.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }

      & .info-field {
        float: left;
        line-height: $base-offset * 1.5;
        font-size: 14px;
        font-family: $font-book;
        margin-right: $base-offset;

        &-label {
          display: block;
          color: $darker-grey;
        }

        &-value {
          display: block;
          color: $dark;

          &--link {
            color: $blue;
          }
        }
      }

      & button {
        margin: $base-offset / 2 $base-offset / 2 $base-offset / 2 0;
        float: right;
      }
    }
  }

  & .generic-details {
    width: 100%;
    height: calc(100% - #{$base-offset} * 4.5);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    &-form {
      height: 100%;
      position: relative;
      @include overflow();

      & .navigation-bar {
        padding: $base-offset $base-offset $base-offset / 2 $base-offset;
        float: left;
        height: 100%;
        width: 40%;
        @include overflow(hidden, scroll);

        & .offset {
          margin-left: $base-offset;
        }

        &-item {
          min-height: $base-offset * 2.5;
          position: relative;
          cursor: pointer;
          background-color: #fff;
          border-radius: 4px;
          margin-bottom: $base-offset / 2;
          font-size: 14px;
          font-family: $font-book;
          line-height: $base-offset * 1.5;
          padding: $base-offset / 2 $base-offset / 2 $base-offset / 2 $base-offset * 3;

          & > .fa {
            font-size: 24px;
            color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: $base-offset * 2.5;
            height: 100%;
            line-height: $base-offset * 2.5;
            text-align: center;
            border-radius: 4px 0 0 4px;

            &::before {
              display: block;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate3d(-50%, -50%, 0);
            }
          }

          &.readonly {
            filter: grayscale(1);
          }

          &.company {
            & .fa {
              background-color: $red;
            }
          }

          &.contract {
            min-height: 64px;
            $hour-glass-size: 56px;
            position: relative;
            padding-right: $base-offset + $hour-glass-size;

            & > .fa {
              background-color: $green;
            }
          }

          &.system, &.project {
            & .fa {
              background-color: $orange;
            }
          }

          &.part {
            $counts-width: 70px;

            & .fa {
              background-color: $blue;
            }

            font-size: 14px;
            line-height: 16px;
            padding: 0 0 0 $base-offset * 2.5 + $counts-width;
            min-height: $base-offset * 4;

            & .part-counts {
              position: absolute;
              width: 70px;
              height: 100%;
              top: 0;
              left: $base-offset * 2.5;

              // padding: $base-offset / 2;
              background-color: rgba($blue, .2);

              & p {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);

                & span {
                  white-space: nowrap;
                  display: block;
                  margin: 0 0 $base-offset / 2 0;
                  text-align: center;

                  &:last-of-type {
                    margin: 0;
                  }
                }
              }
            }

            & .part-text {
              // float: left;
              @include overflow();
              padding: $base-offset / 2;

              & p {
                margin: 0 0 $base-offset / 2;

                &:last-of-type {
                  margin: 0;
                }
              }
            }
          }

          &-error-mark {
            display: block;
            position: absolute;
            top: 50%;
            right: $base-offset;
            transform: translate3d(0, -50%, 0);

            background-color: #fff;
            border-radius: 100%;
            box-shadow: 0 0 10px 4px #fff;

            &::before {
              content: '\f06a';
              display: block;
              font-family: FontAwesome;
              font-size: $icon-size;
              color: $red;
            }
          }

          //CONNECTION EFFECT
          &-connector {
            display: block;
            position: absolute;
            z-index: 0;
            top: $base-offset / 2;
            bottom: $base-offset / 2;
            width: $base-offset;
            right: -$base-offset;
            background-color: #fff;
          }

          &::before, &::after {
            content: '';
            display: block;
            background-color: $lighter-grey;
            width: $base-offset;
            height: calc(50%);
            position: absolute;
            z-index: 1;
            right: -$base-offset;
          }

          &::before {
            top: $base-offset / 2;
            border-radius: 0 0 100px 100px;
            @include transition(.3s);
          }

          &::after {
            bottom: $base-offset / 2;
            border-radius: 100px 100px 0 0;
            @include transition(.3s);
          }

          &.active {
            &::before {
              top: -$base-offset / 2;
              @include transition(.3s);
            }

            &::after {
              bottom: -$base-offset / 2;
              @include transition(.3s);
            }

            &.part {
              background: -moz-linear-gradient(left, rgba($blue, .2) 0%, #fff 100%);
              background: -webkit-linear-gradient(left, rgba($blue, .2) 0%, #fff 100%);
              background: linear-gradient(to right, rgba($blue, .2) 0%, #fff 100%);
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#fff', GradientType=1);
            }

            &.contract {
              background: -moz-linear-gradient(left, rgba($green, .2) 0%, #fff 100%);
              background: -webkit-linear-gradient(left, rgba($green, .2) 0%, #fff 100%);
              background: linear-gradient(to right, rgba($green, .2) 0%, #fff 100%);
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#fff', GradientType=1);
            }

            &.system, &.project {
              background: -moz-linear-gradient(left, rgba($orange, .2) 0%, #fff 100%);
              background: -webkit-linear-gradient(left, rgba($orange, .2) 0%, #fff 100%);
              background: linear-gradient(to right, rgba($orange, .2) 0%, #fff 100%);
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#fff', GradientType=1);
            }

            &.company {
              background: -moz-linear-gradient(left, rgba($red, .2) 0%, #fff 100%);
              background: -webkit-linear-gradient(left, rgba($red, .2) 0%, #fff 100%);
              background: linear-gradient(to right, rgba($red, .2) 0%, #fff 100%);
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#fff', GradientType=1);
            }
          }
        }

        &__closed-separator {
          text-align: right;
          border-bottom: 1px solid $grey;
          padding: $base-offset / 2 0;
          margin: $base-offset * 2.5 0 $base-offset;
        }

        &__separator-text {
          font-family: $font-book;
          font-weight: 500;
          color: $grey;
          font-size: 16px;
        }
      }

      & .form {
        @include overflow(hidden, scroll);
        border-radius: 4px 4px 0 0;
        background-color: #fff;
        float: left;
        padding: $base-offset * 2;
        height: 100%;
        position: relative;
        width: 60%;

        // & .part-realizations {
        //     // position: absolute;
        //     // width: 100%;
        //     // left: 0;
        //     // bottom: 0;
        // }
      }

      h3 {
        font-family: $font-book;
        font-weight: 500;
        font-size: 16px;
        color: $dark;
        display: block;
        margin-bottom: $base-offset;

        & .fa {
          margin-right: $base-offset / 2;
        }
      }

      .section-block {
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
        padding: $base-offset * 2;
        margin-bottom: $base-offset * 1.5;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      & > a {
        font-family: $font-book;
        font-size: 16px;
        color: $darker-grey;

        &:hover {
          text-decoration: underline;
        }
      }

      & .top-info {
        margin-bottom: $base-offset;

        & .time, & .status {
          font-family: $font-book;
          font-size: 12px;
          line-height: 12px;
          color: $darker-grey;
        }

        & .time {
          & .fa {
            margin-right: $base-offset / 2;
          }

          float: left;
        }

        & .status {
          float: right;
          position: relative;

          & > span {
            margin-left: $base-offset / 4;
            padding-right: $base-offset * .75;

            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              right: 0;

              width: $base-offset / 2;
              height: $base-offset / 2;
              line-height: 12px;
              border-radius: 100%;
              border: 1px solid #fff;
            }
          }

          &.complete {
            & > span {
              color: $green;

              &::after {
                background-color: $green;
                border-color: $green;
              }
            }
          }

          &.floating {
            & > span {
              color: $orange;

              &::after {
                background-color: $orange;
                border-color: $orange;
              }
            }
          }

          &.hold {
            & > span {
              color: $blue;

              &::after {
                background-color: #fff;
                border-color: $blue;
              }
            }
          }

          &.inProgress {
            & > span {
              color: $blue;

              &::after {
                background-color: $blue;
                border-color: $blue;
              }
            }
          }

          &.failed {
            & > span {
              color: $red;

              &::after {
                background-color: $red;
                border-color: $red;
              }
            }
          }
        }
      }

      & .entity-details-form {
        & .field-group {
          // margin-bottom: $base-offset * 2;
          & > .editable-field {
            // margin-bottom: $base-offset;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }

      & .file-add, & .right-link {
        margin-top: $base-offset;

        & a {
          float: right;
          color: $darker-grey;
          font-size: 14px;
          margin-left: $base-offset;

          &:last-of-type {
            margin-left: 0;
          }

          & .fa {
            margin-right: $base-offset / 2;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        &.blue {
          & a {
            color: $blue;
          }
        }
      }

      & .edit-entity {
        margin-top: $base-offset * 2;
        float: right;
      }

      & .custom-field {
        width: 100%;
        $label-width: 115px;
        margin: $base-offset / 4 0;
        min-height: $base-offset * 2;

        position: relative;
        padding-left: $label-width;

        & > label {
          position: absolute;
          top: 0;
          left: 0;
          font-family: $font-book;
          font-weight: 500;
          font-size: 12px;
          color: $darker-grey;
          float: left;
          text-transform: capitalize;
          width: $label-width;
          line-height: $base-offset * 2;
          height: $base-offset * 2;
        }
      }

      & .info-list {
        margin-top: $base-offset / 2;
        margin-bottom: $base-offset * 2;
        color: $darker-grey;
        font-family: $font-book;
        font-size: 12px;

        & label {
          float: left;
          margin-right: $base-offset / 2;
        }

        & p {
          float: left;

          &.row {
            width: 100%;
            height: $base-offset;
          }
        }

        a {
          display: inline-block;
          color: $blue;
          margin-left: $base-offset / 4;
          text-decoration: underline;
        }

        span {
          color: $blue;
          margin-left: $base-offset / 4;
          // text-decoration: underline;
        }
      }

      .generic-entity-form-buttons {
        z-index: 2;
        position: fixed;
        padding: $base-offset;
        background-color: #fff;
        top: 72px;
        right: 16px;
        border-radius: 4px;

        & .button-group {
          & button {
            float: left;
            border: 0;
            color: #fff;
            height: 32px;
            line-height: 32px;
            font-size: 16px;
            width: 55px;
            cursor: pointer;

            &.cancel {
              background-color: $red;
              border-radius: 0 4px 4px 0;

              &:disabled {
                color: $lighter-grey;
                background-color: lighten($color: $darker-grey, $amount: 50);
              }
            }

            &.submit {
              background-color: $green;
              border-radius: 4px 0 0 4px;

              &:disabled {
                color: $lighter-grey;
                background-color: lighten($color: $darker-grey, $amount: 30);
              }
            }
          }
        }

        & .roundButton {
          width: 110px;
        }
      }
    }

    .button-transition-enter {
      display: block;
      @include overflow();
      height: 0;
    }

    .button-transition-enter.button-transition-enter-active {
      $wrap-destination-height: 36px + $base-offset / 2;
      height: $wrap-destination-height;
      @include transition(.3s);
    }

    .button-transition-leave {
      display: block;
      $wrap-destination-height: 36px + $base-offset / 2;
      height: $wrap-destination-height;
      @include overflow();
    }

    .button-transition-leave.button-transition-leave-active {
      height: 0;
      @include transition(.3s);
    }

    .add-part-btn-wrap {
      @include overflow();
    }

    .add-part-btn {
      width: 100%;
      background: none;
      border: 0;
      border-radius: 4px;
      padding: $base-offset / 2 $base-offset * 2;
      display: block;
      font-family: $font-book;
      font-size: 16px;
      color: $blue;
      background-color: #fff;
      margin-bottom: $base-offset / 2;
      // margin-left: $base-offset * 2;
      // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
      @include transition(.3s);

      & .fa {
        margin-right: $base-offset / 2;
      }

      &:active, &:focus {
        outline: none;
      }

      &:active {
        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, .1);
        @include transition(.1s);
      }
    }
  }
}

@media (max-width: 768px) {
  .generic-entity-details-page {
    .top {
      .main-part {
        margin: 0;

        .info-field {
          float: none;
        }
      }
    }

    .hour-glass__wrap {
      display: none;
    }

    .generic-details-form {
      .navigation-bar {
        width: 100%;

        .offset {
          margin-left: 0;
        }
      }

      .form {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .generic-details {
      height: auto;

      .navigation-bar {
        &-item {
        }
      }
    }
  }
}

#contract-dates {
  .bp3-popover-wrapper {
    float: none;
  }
}
