.Select {

    &-control {
        display: inline-block !important;
        padding: ($base-offset / 2 - 1) $base-offset / 2;
        border-radius: 4px !important;
        border: 1px solid $grey !important;

        height: 24px !important;
        width: calc(100% - 18px) !important;
        box-sizing: content-box !important;
        -moz-box-sizing: content-box !important;

        &:hover {
            @include transition(.3s);
            box-shadow: 0px 1px 2px 0 $grey;
        }
    }

    &-placeholder, &--single > &-control &-value {
        padding-left: 0 !important;
    }

    &-multi-value-wrapper {
        line-height: 24px;
    }

    &-value {
        font-size: 14px;
        font-family: $font-book;
        line-height: $icon-size !important;
        color: $dark;

        top: $base-offset / 2 !important;
        left: $base-offset / 2 !important;
        bottom: $base-offset / 2 !important;
        right: $base-offset * 3 !important;
        padding: 0 !important;

        @include overflow();

        & > span {
            display: block;
        }
    }

    &-placeholder {
        font-size: 14px;
        font-family: $font-book;
        padding: 0;
        margin: 0;
        top: $base-offset / 2 !important;
        left: $base-offset / 2 !important;
        bottom: $base-offset / 2 !important;
        right: 40px !important;
        line-height: $icon-size !important;
        color: $grey !important;
        color: red !important;
    }

    &-input {
        height: 100% !important;
        padding: 0 0 0 $base-offset / 2 !important;
        width: 100% !important;

        input {
            color: black;
            font-size: 14px !important;
            height: 100% !important;
            font-family: $font-book;
            line-height: $icon-size !important;
            padding: 0 !important;
            box-sizing: border-box !important;
        }
    }

    &-menu-outer {
        z-index: 3 !important;
    }

    &-arrow-zone {
        padding: 0 !important;
        width: $icon-size !important;
        height: $icon-size !important;

        display: block !important;
        position: absolute !important;
        top: 50%;
        right: 8px;
        transform: translateY(-40%);
    }

    &-arrow {
        border-color: $grey transparent transparent;
    }

    &-clear-zone {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);

        &:hover {
            & .Select-clear {
                transition: .3s;
                color: $dark;
            }
        }
    }

    &.is-disabled {
        & .Select-control {
            background: none;
            border-color: $lighter-grey;
        }
    }
}

form label.required {
    &::after {
        content: '*';
        color: $red;
        display: inline;
    }
}

input, textarea {
    position: relative;
    padding: ($base-offset / 2 - 1) $base-offset / 2;
    width: 100%;
    font-family: $font-book;
    font-size: 16px;
    line-height: $icon-size;
    color: #000000;
    border: 1px solid rgba($border-color, 0.16);
    border-radius: 2px;
    @include placeholderColor($grey);
    @include transition(.3s);

    &:focus {
        outline: none;
        box-shadow: $shadow-focus;
    }

    &:hover {
        box-shadow: none;
    }
}

.disabled {

    & .select {
        opacity: .6;

        & > div {
            border-color: #DDDDDD !important;

        }

        & .css-1fhf3k1-control {
            background-color: #EBEBEB;
        }

        & .css-1wa3eu0-placeholder {
            color: #808080 !important;
            opacity: .6;
        }
    }
}

.select {
    & input:disabled {
        background-color: transparent !important;
    }
}

.ls-form {
    margin-bottom: $base-offset * 1.5;
    // min-width: 140px;
    position: relative;

    &::after {
        content: '';
        display: block;
        clear: both;
    }

    & form {
        overflow: visible;
    }

    & > label {
        display: block;
        color: $darker-grey;
        font-family: $font-book;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: $base-offset / 2;
    }

    &.ls-textarea {
        & textarea {
            float: left;
        }

        &.editable {
            position: relative;

            &::before {
                content: '';
                display: block;

                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                z-index: 2;

                background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
                background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
            }

            textarea {
                z-index: 1;
                overflow: hidden;

                &:focus {
                    z-index: 3;
                    overflow: scroll;
                }
            }
        }
    }

    &.ls-textinput, &.ls-textarea {

        &.editable {
            input, textarea {
                padding: ($base-offset / 2 - 1) 0;
                border-color: transparent;
                background-color: transparent;

                &:focus {
                    transition: .05s;
                    background-color: #fff;
                    padding: ($base-offset / 2 - 1);
                    border: 1px solid $blue;
                    box-shadow: 0px 0px 4px 0px $blue;
                }

                &:hover {
                    box-shadow: none;
                }
            }

            textarea {
                overflow: hidden;

                &:focus {
                    overflow: scroll;
                }

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: $base-offset * 2;
                }
            }
        }

        textarea {
            resize: none;
            min-height: 62px + $base-offset;
        }

        & .opt-buttons {
            @include overflow();

            & .opt-button {
                float: left;
                font-family: $font-book;
                font-size: 11px;
                transition: .3s;
                background-color: $lighter-grey;
                border: 0;
                border-radius: 20px;
                color: $dark;
                line-height: 24px;
                padding: 0 $base-offset / 2;
                cursor: pointer;
                margin: $base-offset / 2 $base-offset / 2 0 0;

                & .fa {
                    margin-right: $base-offset / 2;
                }

                &:hover {
                    transition: .3s;
                    background-color: darken($lighter-grey, 10);
                }
            }
        }

        &.numberinput {

            & .numberinput-wrap {

                &:hover {
                    & input {
                        border-color: rgba($blue, .3);
                    }
                }

                &:focus {
                    & input {
                        border-color: rgba($blue, 1) !important;
                    }
                }

                input {
                    color: black;
                    padding: $base-offset / 2 - 1 $base-offset * 3;

                }

                position: relative;
                width: 100%;
                height: 100%;
                @include clear();

                & > button {
                    height: 100%;
                    width: $base-offset * 2;
                    position: absolute;
                    top: 0;
                    border: 0;
                    background-color: transparent;
                    line-height: 100%;
                    text-align: center;
                    color: $blue;

                    &:focus {
                        outline: 0;
                    }

                    & svg {
                        margin-right: 0;
                    }

                    &.decrease {
                        top: 2px;
                        left: 0;
                    }

                    &.increase {
                        top: 2px;
                        right: 0;
                    }
                }
            }
        }

        & input[disabled] {
            color: #808080;
            background: #EBEBEB;
            border: 1px solid #DDDDDD;
            opacity: .6;

            &:hover {
                border-color: #DDDDDD;
            }
        }
    }

    &.ls-select {
        &.multiselect {

            & input {


                &:focus {
                    box-shadow: unset;
                }
            }

            & .Select {
                float: left;
                width: 100%;
            }

            & .Select-arrow-zone, & .Select-clear-zone {
                display: none;
            }

            & .Select-multi-value-wrapper {
                padding: $base-offset / 4;
                width: 100%;
            }

            & .Select-value {
                width: 100%;
                margin: 0 0 4px 0px;
                line-height: initial;

                background-color: transparent;
                border-color: $lighter-grey;
                padding: 2px 0;
            }

            & .Select-placeholder {
                display: none;
            }

            & .Select-input {
                //height: 24px;
                position: relative;
                width: 100%;
                margin: 0;
                padding: 0 0 0 8px;
                height: 36px;

                border: 1px solid $lighter-grey;
                border-radius: 2px;

                & input {
                    color: black;
                    width: 100%;
                    height: 36px;
                    line-height: 36px;

                    &:focus {
                        box-shadow: unset;
                    }
                }


            }

            & .Select-value-icon {
                border: 0;

                &:hover {
                    background-color: transparent;
                    color: $red;
                }
            }

            & .Select-value-label {
                font-size: 14px;
                color: $dark;
            }

            & .Select-multi-value-wrapper {
                font-size: 14px;
            }
        }
    }

    &.ls-dateinput {

        & .bp3-popover-wrapper {
            width: 100%;
        }

        input {
            padding: 7px 30px 7px 8px;
            width: 100%;
            font-family: $font-book;
            font-size: 16px;
            line-height: 24px;
            height: auto;
            max-height: 38px;
            color: black;
            border-radius: 2px;
            border: 1px solid $grey;
            box-shadow: none;
        }

        .pt-input-action {
            top: 50%;
            margin-top: -15px;
        }

        .pt-popover-target {
            width: 100%;
        }

        &--full-width {
            display: flex;

            .bp3-popover-wrapper {
                float: none;

                .bp3-popover-target {
                    width: 110%;

                    .bp3-input-group {
                        padding-right: 22px;

                        input {
                            border-radius: 4px 0 0 4px;
                        }
                    }
                }
            }

            .roundButton--clear {
                width: 15%;
                margin: 0;
                padding-bottom: 36px;
                line-height: 40px;
                border-radius: 0 5px 5px 0;

                .fa-close {
                    margin-left: -2px;
                    font-size: 16px;
                }
            }
        }
    }

    &.ls-radiogroup {
        .ls-radiogroup-wrap {
            overflow-x: hidden;
            overflow-y: hidden;
            width: 100%;

            & div {
                float: left;
            }

            & input[type='radio'] {
                display: none;

                &:checked + label {
                    font-family: $font-book;
                    font-weight: 500;
                    font-size: 11px;

                    background-color: $blue;
                    color: #fff;

                    &.green {
                        background-color: $green;
                    }

                }
            }

            & label {
                display: block;
                float: left;
                cursor: pointer;
                margin-bottom: 0;

                font-family: $font-book;
                font-weight: 500;
                font-size: 11px;
                color: $dark;
                text-transform: uppercase;

                padding: $base-offset / 2 $base-offset;
                border-radius: 4px;

                &:hover {
                    transition: .3s;
                    background-color: rgba($blue, .1);
                }

                &.green {
                    transition: .3s;

                    &:hover {
                        background-color: rgba($green, .1);
                    }
                }
            }
        }
    }

    &.ls-daterange {
        position: relative;
        min-width: 250px;

        & input {
            padding: ($base-offset / 2 - 1) $base-offset / 2;
            width: 100%;
            font-family: $font-book;
            font-size: 14px;
            line-height: 24px;
            color: black;
            border-radius: 4px;
            border-width: 0 !important;
            height: 36px !important;
        }

        & .fa {
            position: absolute;
            width: 24px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            top: $base-offset / 2 + 22px;
            right: $base-offset / 2;
            color: $dark;

            &:hover {
                text-decoration: none;
            }
        }

        & .date-range-picker {
            position: absolute;
            z-index: 2;
            top: 64px;
            left: 8px;
            border-radius: 4px;
            border: 1px solid $grey;
        }

        & .dropdown {
            z-index: 2;
            position: absolute;
            width: 100%;
            bottom: 0px;
            transform: translateY(calc(100% - 1px));
            font-size: 14px;
            font-family: $font-book;
            background-color: #fff;
            padding: 8px 0 !important;
            border: 1px solid #dddddd;

            & div {
                margin-bottom: 4px;
                cursor: pointer;
                transition: 300ms;
                padding: 8px;

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    background-color: rgba($blue, .3);
                }
            }
        }

        & .replaceable-inputs {
            width: 100%;
            border: 1px solid #dddddd;
            position: relative;
            max-height: 38px;

            & .toggle-inputs {
                position: absolute;
                top: 0;
                right: 0;
                color: #dddddd;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;

                &.hide {
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                }

                & svg {
                    width: 24px;
                }
            }
        }
    }

    &.ls-checkbox {
        & .field {

            $size: 16px;

            input[type='checkbox'] {
                display: none;
            }

            & label {
                cursor: pointer;
                position: relative;
                font-family: $font-book;
                color: $dark;
                font-size: 14px;
                line-height: $size;
                height: $size;
                padding: 0 0 0 ($size + $base-offset / 2);
                display: block;

                &::before {
                    transition: .3s;
                    content: '';
                    position: absolute;
                    top: 0px;
                    left: 0px;

                    width: $size;
                    height: $size;
                    border: 1px solid $grey;
                    border-radius: 2px;
                }
            }

            input[type='checkbox']:checked + label {
                &::before {
                    transition: .3s;
                    background-color: $blue;
                    border-color: $blue;
                }

                &::after {
                    content: '\f00c';
                    font-family: FontAwesome, sans-serif;
                    font-size: 12px;
                    text-align: center;
                    color: #fff;
                    line-height: $size;
                    width: $size;
                    height: $size;

                    position: absolute;
                    left: 0px;
                    top: 0px;
                }
            }

            input[type='checkbox']:disabled + label {
                color: $darker-grey;

                &::before {
                    transition: .3s;
                    background-color: $grey;
                    border-color: $grey;
                }
            }
        }
    }

    & .ls-error {
        // display: inli;
        color: $red;
        font-size: 10px;
        margin-top: $base-offset / 4;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -16px;
    }
}

.expired-contract-warning {
    margin-bottom: 16px;
    color: orange;
}
