.nested-table {
  & .row {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    & .cell {
      flex-basis: 100%;
      padding: $base-offset $base-offset * 2 $base-offset $base-offset;
    }
  }
}
