
.login {
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('./../../media/login-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  .back-to-login {
    margin-bottom: $small-offset;
  }

  .small-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #7A7F82;
    margin-bottom: $base-offset;

    &_blue {
      color: $primary-button;
    }
  }

  .medium-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #282A2B;
    margin-bottom: $base-offset;
  }

  .ls-textinput {
    position: relative;
    @include transition(300ms);

    & input {
      height: 32px;
      background: #fff;
      transition: 300ms;
      color: black;

      &:hover {
        border: 1px solid rgba($border-color, 1);
      }

      &:focus {
        background: transparent;
        box-shadow: $shadow-focus;
      }
    }

    &:hover {
      & svg {
        fill: $blue;
      }
    }

    & .focus {
      fill: $border-color;
    }
  }

  .roundButton {
    cursor: pointer;
    background: #285165;
    color: white;
    border: none;
    margin-top: 32px;
  }

  .form-side {
    position: relative;
    height: 100%;
    background-color: #ffffff;
    width: 39.84%;
    max-width: 510px;
    padding: 0 $high-offset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .logo {
    width: 180px;
    margin-top: auto;
    margin-bottom: $medium-offset;
  }

  .error {
    color: $red;
  }

  .certificates {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: auto;
    grid-column-gap: 2%;

    .certificate-img {
      align-self: center;
      margin-bottom: 20px;
    }
  }

  & .login-form {
    width: 100%;
    margin-bottom: 20px;

    & .form-field {
      margin-bottom: $base-offset;
    }

    &__pre-text {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }

  & .two-factor {

    & .row-1,
    & .row-2 {
      width: 100%;
    }

    & .qr-code-wrap {
      text-align: center;
      padding: 7px;
      border: 1px solid #E6E6E6;
      border-radius: 2px;
      max-height: 128px;
      max-width: 128px;
      margin: 0 auto $small-offset;

      & img {
        width: 100%;
      }
    }

    & .secret-code {
      display: block;
      border: 1px solid #E6E6E6;
      border-radius: 2px;
      text-align: center;
      padding: 4px 10px;
      font-size: 16px;
      line-height: 21px;
      margin: $base-offset 0 $small-offset;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .login {

    & .form-side {
      padding: 0 $regular-offset;
    }
  }
}

@media only screen and (max-width: 768px) {
  .login {

    & .form-side {
      width: 380px;
      padding: 0 $small-offset;
    }
  }
}

@media only screen and (max-width: 480px) {
  .login {
    align-items: center;
    justify-content: center;
    padding: 0 $base-offset;

    & .form-side {
      border-radius: 2px;
      height: auto;
      padding: $small-offset;

      & .certificate-img {
        display: none;
      }
    }
  }
}

