.save-segment {
    margin-right: auto;
    cursor: pointer;
    width: 330px;
    max-width: 200px;
    transition: 300ms;
    position: relative;

    &.open {
        max-width: 330px;
    }

    & input {
        padding-right: 38px;
    }

    & .filter-ico {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: 300ms;
        height: 100%;
        width: 40px;
        display: flex;
        justify-content: center;
        right: 0;
        align-items: center;
        padding: 8px;

        &:hover {
            color: $blue;
        }

        & svg {
            width: 100%;
        }
    }
}

.saved-segments {
    display: flex;
    align-items: center;

    &__item {
        display: flex;
        align-items: center;
        background-color: #f5f5f5;
        border-radius: 2px 0 0 2px;
        padding: 4px 24px 4px 8px;
        cursor: pointer;
        position: relative;
        margin-right: 32px;
        transition: 300ms;
        height: 27px;

        &::before {
            content: '';
            height: 20px;
            width: 20px;
            background-color: #f5f5f5;
            transform: rotate(45deg) translateY(-53%);
            position: absolute;
            right: -2px;
            top: 41%;
            border-radius: 2px;
            transition: 300ms;
        }

        &.active {
            background-color: $blue;
            color: white;
            cursor: default;

            &::before {
                background-color: $blue;
            }

            & span {
                cursor: pointer;
                
                &:hover {
                    color: #fff;
                }
            }
        }

        & span {
            width: 16px;
            height: 16px;
            position: absolute;
            right: 0;

            &:hover {
                color: $blue;
            }
        }

        & svg {
            width: 100%;
        }
    }
}

.clear-filters {
    display: flex;
    cursor: pointer;

    & svg {
        margin-left: 12px;
        width: 18px;
    }
}