.dialog {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 50;

  &-back {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(#fff, .85);
    z-index: 50;
    border-radius: 100%;

    animation: confirmBackAppear .8s ease-in-out forwards;
  }

  &-container {
    $container-width: 370px;
    $container-height: 180px;
    position: absolute;
    z-index: 55;
    top: 40%;
    left: 50%;
    opacity: 0;
    // background-color: #fff;
    width: $container-width;
    min-height: $container-height;
    margin: (-$container-height / 2) 0 0 (-$container-width / 2);
    border-radius: 4px;
    // box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, .1);
    @include overflow();

    animation: confirmContainerAppear .3s .3s ease-in-out forwards;
  }

  &-title {

    // padding: $base-offset;
    // background-color: $orange;
    color: $dark;;
    font-size: 30px;
    // line-height: $icon-size;
    margin-bottom: $base-offset * 2;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &-close {
    position: absolute;
    top: $base-offset;
    right: $base-offset;

    &::before {
      content: '\f00d';
      font-family: FontAwesome;
      font-size: 22px;
      line-height: $icon-size;
      text-align: center;
      transition: .3s;
      color: #fff;

      width: $icon-size;
      height: $icon-size;

      float: left;
    }

    &:hover {
      &::before {
        transition: .3s;
        color: darken(#fff, 5);
      }
    }
  }

  &-message {
    // $icon
    color: $darker-grey;
    padding: 0 0 0


  }

  & .ls-form {
    margin: $base-offset 0;
  }

  &-buttons {
    bottom: 0;
    left: 0;
    margin-top: $base-offset;

    & .roundButton {
      margin-right: $base-offset;
    }
  }

  &.close {
    & .dialog-back {
      animation: confirmBackHide .3s ease-in-out forwards;
    }

    & .dialog-container {
      animation: confirmContainerHide .1s ease-in-out forwards;
    }
  }
}

@keyframes confirmBackAppear {
  from {
    width: 0;
    height: 0;
    margin: 0 0 0 0;
  }
  to {
    width: 300vw;
    height: 300vw;
    margin: -150vw 0 0 -150vw;
  }
}

@keyframes confirmBackHide {
  from {
    width: 300vw;
    height: 300vw;
    margin: -150vw 0 0 -150vw;
  }
  to {
    width: 0;
    height: 0;
    margin: 0 0 0 0;
  }
}

@keyframes confirmContainerAppear {
  from {
    opacity: 0;
    transform: scale(.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes confirmContainerHide {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(.5);
  }
}
