@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oxygen:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cantarell:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,600,700&display=swap');

//COLORS

$dark: #0D1B2A;

$grey: #BFC0C0;
$text-color: #808080;
$darker-grey: darken($grey, 30);
$lighter-grey: lighten($grey, 20);

$border-color: #005FCE;
$primary-button: #005FCE;

$red: lighten(#D62839, 10);
$orange: lighten(#F06449, 10);
$green: #28CD56;
$blue: #005FCE;

$purple: lighten(#8255D5, 10);

//EFFECTS
$shadow-focus: 0 0 0 3px rgba($border-color, 0.16);

//VALUES
$base-offset: 16px;
$small-offset: 24px;
$regular-offset: 48px;
$medium-offset: 64px;
$high-offset: 96px;
$icon-size: 24px;
$sidebar-width: 200px;
$sidebar-short-width: 64px;

$font-light: 'Whitney Light', sans-serif;
$font-medium: 'Whitney Medium', sans-serif;
$font-semibold: 'Whitney Semibold', sans-serif;
$font-book: 'Whitney Book', sans-serif;
$font-bold: 'Whitney Bold', sans-serif;

@font-face {
  font-family: 'Whitney Semibold';
  src: url('../css/fonts/whitney/Whitney-Semibold.eot');
  src: url('../css/fonts/whitney/Whitney-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../css/fonts/whitney/Whitney-Semibold.woff') format('woff'),
  url('../css/fonts/whitney/Whitney-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney Medium';
  src: url('../css/fonts/whitney/Whitney-Medium.eot');
  src: url('../css/fonts/whitney/Whitney-Medium.eot?#iefix') format('embedded-opentype'),
  url('../css/fonts/whitney/Whitney-Medium.woff') format('woff'),
  url('../css/fonts/whitney/Whitney-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney Book';
  src: url('../css/fonts/whitney/Whitney-Book.eot');
  src: url('../css/fonts/whitney/Whitney-Book.eot?#iefix') format('embedded-opentype'),
  url('../css/fonts/whitney/Whitney-Book.woff') format('woff'),
  url('../css/fonts/whitney/Whitney-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney Bold';
  src: url('../css/fonts/whitney/Whitney-Bold.eot');
  src: url('../css/fonts/whitney/Whitney-Bold.eot?#iefix') format('embedded-opentype'),
  url('../css/fonts/whitney/Whitney-Bold.woff') format('woff'),
  url('../css/fonts/whitney/Whitney-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Whitney Light';
  src: url('../css/fonts/whitney/Whitney-Light.eot');
  src: url('../css/fonts/whitney/Whitney-Light.eot?#iefix') format('embedded-opentype'),
  url('../css/fonts/whitney/Whitney-Light.woff') format('woff'),
  url('../css/fonts/whitney/Whitney-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
