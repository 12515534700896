.part-realizations-manager {
    border-radius: 4px;
    margin: $base-offset * 2 0 0;
    padding: $base-offset;
    background-color: rgba($blue, .075);

    &__item {
        $icon-size: 48px;
        float: left;
        min-width: 33%;


        &__title {
            font-family: $font-book;
            color: $darker-grey;
            font-size: 14px;
            width: 100%;
            display: block;
            margin-bottom: $base-offset;;
        }

        &__icon {
            cursor: pointer;
            float: left;
            margin-right: $base-offset;
            display: block;
            width: $icon-size;
            height: $icon-size;

            background-position: center;
            background-size: contain;
            background-color: $blue;
            border-radius: 4px;
            border: 4px solid $blue;

            &::before {
                content: '';
                float: left;

                background-image: url('./../../../media/hdd-icon.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 80% auto;

                display: block;
                height: 100%;
                width: 100%;
            }

            &--drop-zone {
                border: 4px dashed $darker-grey;
                background-color: transparent;

                &::before {
                    background-image: url('./../../../media/hdd-icon-grey.png');
                    background-repeat: no-repeat;
                }
            }
        }

        &__count {
            color: $darker-grey;
            font-family: $font-book;
            font-size: 14px;
        }

        &--right {
            float: right;
            text-align: right;

            & .part-realizations-manager__item__icon {
                float: right;
                margin: 0 0 0 $base-offset;
            }
        }
    }
}

.part-realizations {
    width: calc(100% - 225px);
    border-radius: 4px;

    &__notes {

        & p {
            max-height: 18px;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &__row {
        display: grid;
        align-items: center;
        width: 100%;
        transition: 300ms;

        grid-template-columns: 20px 30% 1fr 30px 30px;
        grid-template-rows: 40px;
        grid-column-gap: 15px;

        &:first-child {
            border-bottom: 1px solid rgba($blue, .3);
            font-family: $font-bold;
            font-size: 16px;
            color: $blue;
            margin-bottom: 4px;
        }

        &:hover:not(:first-child) {
            background-color: rgba($blue, .2);
        }

        &:last-child {
            border-bottom: 0;
        }

        & .remove,
        & .edit,
        & .assign,
        & .link {
            margin-left: auto;
            display: flex;
            align-items: center;

            &:hover {

                & svg {
                    color: $blue;
                }
            }
            & svg {
                width: 18px;
                height: 18px;
                cursor: pointer;
            }
        }
    }

    & textarea {
        margin-bottom: 24px;
    }

    & button {
        width: 100px;
    }
}
