.editable-field {
  width: 100%;
  $label-width: 115px;
  margin: $base-offset / 4 0;
  min-height: $base-offset * 2;

  & .ls-form {
    margin: 0;

    label {
      display: none;
    }

    & input, & textarea {
      padding-top: $base-offset / 4 - 1;
      padding-bottom: $base-offset / 4 - 1;
      height: $base-offset * 2 !important;
    }

    & .Select {
      line-height: 18px !important;

      &-control {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        line-height: 18px !important;
        height: $base-offset * 2 !important;
      }

      &-value {
        line-height: 18px !important;
      }

      &-placeholder {
        line-height: 18px;
        top: $base-offset / 4 !important;
      }
    }
  }

  position: relative;
  padding-left: $label-width;

  & > label {
    position: absolute;
    top: 0;
    left: 0;
    font-family: $font-book;
    font-weight: 500;
    font-size: 12px;
    color: $darker-grey;
    float: left;
    text-transform: capitalize;
    width: $label-width;
    line-height: $base-offset * 2;
    height: $base-offset * 2;
  }

  & .field-value {
    // @include overflow();
    width: 100%;
    line-height: $base-offset * 2;
    color: $dark;
    font-family: $font-book;
    font-size: 14px;
    position: relative;

    .value-text, .value-link {
      position: relative;
      color: $dark;
      padding-right: $base-offset * 2;
      float: left;
      width: 100%;
      min-height: $base-offset * 2;

      &--info {
        color: $blue;
      }

      &.textarea {
        line-height: $base-offset;
        padding: $base-offset / 2 $base-offset * 2 $base-offset / 4 0;
      }

      &.editable {
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% + #{$base-offset / 2});
          height: 100%;
          left: -$base-offset / 2;
          top: 0;
          border-radius: 4px;
          box-shadow: 0 0 0 0 $grey;
        }

        &:hover {
          &::after {
            box-shadow: 0px 0px 0 1px $grey;
            @include transition(.3s);
          }
        }
      }

    }

    .value-text {
      cursor: default;
      display: inline;
      background-color: transparent;
      padding: 0;
      border: 0;
      text-align: left;
      outline: none;

      &.editable {
        cursor: text;
      }
    }

    .value-link {
      cursor: pointer;
    }

    .value-link:hover {
      text-decoration: underline;
    }

    .edit-link {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      height: $base-offset * 2;
      width: $base-offset * 2;
      font-size: 16px;
      text-align: center;
      color: $darker-grey;

      &:hover {
        color: lighten($darker-grey, 15);
        @include transition(.3s);
      }

      &.error {
        opacity: 1;
        color: $red;

        &:hover {
          color: lighten($red, 15);
        }
      }
    }

    &:hover {
      .edit-link {
        opacity: 1;
        @include transition(.3s);
      }
    }
  }

  & .ls-form {
    width: 100%;
  }

  &.big {
    & .field-value p, & .field-value a {
      font-family: $font-book;
      font-weight: 500;
      font-size: 16px;
      color: $blue;
    }
  }

  &.no-title {
    padding-left: 0;

    & > label {
      display: none;
    }
  }
}
