.autocomplete-input {
  position: relative;
  width: 100%;

  & .select {
    overflow-x: hidden;
  }

  &.withico {

    & svg {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 60%;
    }

    & input {
      padding-left: 30px;
    }
  }

  & input {
    color: black;
    border-radius: 2px;
    padding: 8px 8px;
    border: 1px solid #E6E6E6;
    @include transition(300ms);

    &:hover {
      color: $blue;
      border-color: rgba($blue, .4);
    }

    &:focus {
      border-color: $blue;
    }
  }

  &.list {
    position: relative;

    input {
      color: black;
      border-radius: 4px 4px 0 0;
      box-shadow: 0px 1px 2px 0 $grey;
    }
  }

  ul {
    position: absolute;
    left: 0;
    right: 0;
    background-color: #fff;
    list-style: none;
    border: 1px solid $grey;
    border-radius: 0px 0px 4px 4px;
    bottom: 0;
    transform: translateY(100%);

    padding: 0;
    margin: 0;

    z-index: 2;
    max-height: 160px;
   overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 1px 2px 0 $grey;

    li {
      width: 100%;

      button {
        display: block;
        padding: $base-offset / 2;
        line-height: $icon-size;
        color: $dark;
        border: 0;
        background-color: transparent;
        text-align: left;

        &:focus {
          outline: 0;
        }
      }

      &.active {
        button {
          background-color: transparent;
        }
      }
    }
  }


  .loading-dots {
    height: 10px;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    width: auto;
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    &--dot {
      animation: dot-keyframes 1.5s infinite ease-in-out;
      background-color: rgba(#000000, .5);
      border-radius: 10px;
      display: inline-block;
      height: 7px;
      width: 7px;
      margin-right: 4px;

      &:nth-child(2) {
        animation-delay: .5s;
      }

      &:nth-child(3) {
        animation-delay: 1s;
      }
    }
  }
}
