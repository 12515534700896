.contract-navigation {

    .form-wrapper.contract-navigation-section {
        padding-right: 0;
    }

    .alone {

        .list-dot {
            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-60%) translateY(-42%);
                width: 6px !important;
                height: 6px !important;
            }
        }
    }

    .hide {
        max-height: 80px;

        & > ul {
            opacity: 0;
            transform: translateY(-10%);
        }

        &:not(.alone) {
            .list-dot {

                &::after {
                    display: none;
                }
            }
        }
    }

    .show {

        & > ul {
            opacity: 1;
            transform: translateY(0);
            visibility: visible !important;
        }

        & > .li-title {
            .list-dot {

                .toggle-contract {
                    opacity: 0;
                }
            }
        }

        &:not(.alone) {
            & > .li-title:hover {
                .list-dot {

                    .toggle-contract {
                        opacity: 1;
                    }

                    &::after {
                        opacity: 0;
                    }
                }
            }
        }
    }

    .contracts:not(.alone) {

        & > ul {
            transition: 300ms opacity, 200ms transform;
            visibility: hidden;
        }

        .toggle-contract {
            height: 100%;
            display: flex;
            width: 100%;
            position: absolute;
            z-index: 9;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-53%);
        }
    }

    & .tile {

        & .tile-item {
            flex-basis: calc(100% / 2 - 12px) !important;

            &.expenses {
                width: 50%;
                margin-right: 24px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    & .form-wrapper.contract-navigation-section {
        width: 30%;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 330px;
        max-width: 350px;
    }

    .checkbox-list {
        min-width: 16px;
        width: 16px;
        height: 16px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        visibility: hidden;


        & svg {
            width: 100%;
            height: 100%;
            margin-right: 0;
        }

        & .checkbox {
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            @include transition(300ms);

            &.selected {
                opacity: 1;
                visibility: visible;
            }
        }

        & label {
            cursor: pointer;
        }

        & .checked {
            fill: $blue;
        }

    }

    & ul {
        padding-left: 0px;
        width: 100%;

        > li {
            padding-left: 28px;
            left: -14px;
        }
    }

    & li {
        position: relative;
        list-style: none;
    }

    & .assigned-to {
        border-bottom: 1px solid transparent;
        position: sticky;
        top: -24px;
        margin: 0 -24px;
        padding: 17px 16px 1px;
        background-color: #ffffff;
        z-index: 11;
        @include transition(400ms);

        & .ls-form {
            transform: translateY(1px);

            & label {
                min-width: 100px;
                margin-bottom: 0;
                margin-right: 44px;
            }
        }

        &.showed {
            max-height: 200px;
            border-color: #DDDDDD;
        }
    }

    & .li-title {
        display: flex;
        align-items: center;
        position: relative;
        font-size: 16px;
        line-height: 21px;
        height: 80px;
        padding: 5px 0 5px 40px;
        color: #282A2B;
        cursor: pointer;

        &:hover {

            & .duration-info:not(.no-hide) {

                & .icon {
                    opacity: 0;
                    visibility: hidden;
                }

                & .duration-item {
                    opacity: 1;
                    visibility: visible;
                }
            }

            & .checkbox {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .has-stock{

        & svg {

            & path {
                fill: #005FCE !important;
            }
        }
    }

    .has-spare {

        & svg {

            & path {
                fill: #28CD56 !important;
            }
        }
    }




    .second-step {
        width: 105%;

        & > li {
            width: 105%;

            & > .li-title {
                padding-right: 24px;
            }
        }
    }

    .first-step,
    .second-step,
    .third-step {
        position: relative;

        & > li {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 40px;
                left: 14px;
                height: calc(100% - 80px);
                width: 1px;
                background-color: #C4C4C4;
            }

            .list-dot {
                z-index: 9;
            }

            &.active {
                font-family: $font-book;

                & .list-dot {

                    &::after {
                        background-color: $blue;
                        border-color: $blue;
                    }
                }
            }

            &.closed {
                font-family: $font-book;

                & svg {
                    fill: #808080 !important;
                    color: #808080 !important;
                }

                & .list-dot {

                    &::after {
                        background-color: #808080;
                        border-color: #808080;
                    }
                }
            }

            & .blue {

                & svg {
                    fill: $blue !important;
                }
            }
        }


        & .list-dot {
            &::before {
                content: '';
                position: absolute;
                top: 48%;
                left: -9px;
                width: 8px;
                height: 1px;
                background-color: #C4C4C4;
                z-index: -1;
            }
        }
    }

    & .system,
    & .projects {

        & .parts {

            & .list-dot {
                display: flex;
            }
        }
    }

    & .duration-info {
        width: 55px;
        height: 55px;
        position: absolute;
        left: -13px;

        &.show {

            & .icon {
                display: none;
            }
        }

        & .icon {
            @include transition(300ms);

            &.no-hidden {
                opacity: 1 !important;
                visibility: visible !important;
            }

            & svg {
                min-width: 24px;
                width: 24px;
                height: 24px;
                margin: 16px;
                fill: #808080;

            }
        }

        & .duration-item {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: $font-medium;
            font-size: 14px;
            opacity: 0;
            visibility: hidden;
            @include transition(300ms);

            & > span {
                display: flex;
                flex-direction: column;
                align-items: center;
            }


            & svg {
                width: 20px;
            }

            &.green {
                color: #28CD56;

                & svg {
                    fill: #28CD56;
                }
            }

            &.orange {
                color: #FF9A23;

                & svg {
                    fill: #FF9A23;
                }
            }

            &.red {
                color: #F06262;

                & svg {
                    fill: #F06262;
                }
            }

            &.grey {
                color: #808080;
                fill: #808080;
            }
        }

        &.show {

            & .duration-item {
                opacity: 1;
                visibility: visible;
            }
        }
    }


    & .list-dot {
        position: absolute;
        left: -20px;
        min-width: 13px;
        min-height: 13px;
        border-radius: 50%;
        border: 1px solid #C4C4C4;
        z-index: 1;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: '';
            position: relative;
            width: 9px;
            height: 9px;
            border: 1px solid $blue;
            border-radius: 50%;
        }
    }

    .first-step {

        & > li {

            & > .li-title {

                & .list-dot {

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    .third-step {

        &::before {
            content: '';
            position: absolute;
            top: 40px;
            left: 0;
            height: calc(100% - 80px);
            width: 1px;
            background-color: #C4C4C4;
        }

        & .list-dot {

        }
    }

    .alone {

        .toggle-contract {
            display: none !important;
        }

        &::before {
            height: 0 !important;
        }
    }


    .last {

        &::after {
            content: '';
            height: 100%;
            width: 5px;
            background-color: #fff;
            position: absolute;
            left: 0;
            top: 40px;
        }

        & .decorate-block {
            width: 7px;
            height: 39px;
            background-color: #fff;
            position: absolute;
            left: -28px;
            bottom: 39px;
            z-index: 10;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                border-left: 1px solid #C4C4C4;
                border-bottom: 1px solid #C4C4C4;
                height: 100%;
                width: 100%;
                border-bottom-left-radius: 5px;
            }
        }
    }

    & .third-step {
        z-index: 1;
    }

    & .fourth-step {
        position: relative;
        z-index: 1;
    }

    .closed.has-children {

        & .list-dot {

            &::after {
                background-color: $blue;
            }
        }

        & > ul {
            max-height: 0;
            overflow: hidden;
        }
    }

    & .opened {

        & > ul {
            max-height: 99999px;
        }

        & .list-dot {

            &::after {
                background-color: #fff;
            }
        }
    }

    & .li-title.selected {

        & > .list-dot {
            z-index: 99;

        }

        &::before {
            content: '';
            position: absolute;
            z-index: 11;
            top: 0;
            left: -100%;
            width: 200vw;
            height: 100%;
            background-color: rgba($blue, .08);
        }

        & svg {
            fill: $blue;
        }

        & .checkbox-list {
            z-index: 11;

            & svg {
                fill: black;

                &.checked {
                    fill: $blue;
                }
            }
        }
    }

}
