@media only screen and (max-width: 1110px) {
    .center-form-container {

        & .parts-item {
            padding: 14px 8px;

            & .remove {
                top: 12px;
            }
        }

        & .form-wrapper {
            width: 70%;
        }

        & .right-panel {
            width: 30%;

        }
    }

    .center-form-container {

        &.quote-details {

            & .right-panel {
                & .chooses-content {

                    & .cards {
                        width: calc(100% / 3 - 10px);
                        margin-bottom: 14px;

                        &:nth-child(4),
                        &:nth-child(5) {
                            width: calc(100% / 2 - 8px);
                        }
                    }
                }
            }
        }
    }
}



@media only screen and (max-width: 1024px) {
    .dashboard__summaries {

        & .dashboard__summary-widget {
            width: calc(100% / 2 - 5px);
            min-width: 237px;

            &:nth-child(odd) {
                margin-right: 10px;
            }
        }
    }

    .context-menu {

        & .categories {
            position: absolute;
            height: auto;
            width: auto;
            box-shadow: 0px 4px 16px rgba(54, 56, 57, 0.16);
            background-color: #fff;
            right: 0;
            bottom: 75px;
            min-width: 330px;
            max-height: 70vh;
            overflow-y: scroll;

        }
    }
    .center-form-container {

        & .form-wrapper {
            & .back {
                &.hidden {
                    display: flex;
                }
            }
        }
    }
}

@media only screen and (max-width: 892px) {
    .center-form-container {

        & .form-wrapper {
            min-width: 0;
        }

        & .tile-item {
            width: calc(100% / 2 - 8px);
            margin-bottom: 16px;

            &:nth-child(2) {
                width: 100%;
            }

            & .expenses-drop {
                position: absolute;
                width: calc(100% + 2px);
            }
        }
    }

    .contract-navigation,
    .form-wrapper {

        & .ls-form {

            & label {
                min-width: 150px;
                max-width: 150px;
            }
        }
    }

    .table-wrap {

        & .main-table {
            overflow-x: scroll;


            & > div {
                width: 1000px;
                padding-right: 24px;
            }

            &.table-customers {
                & > div {
                    width: 850px;
                }
            }

            & .table-row {

                &::before {
                    width: calc(100% + 44px);
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {

    .content-container {
        overflow-x: visible !important;
        overflow-y: visible !important;
    }

    .sidebar {
        max-width: 64px;
        max-height: 0;

        &.full {
            max-width: 225px;
            max-height: 9999px;
        }

        &-item {

            &-label {
                margin-left: 0;
            }
        }
    }

    .main-wrap {

        & .content-container {
            padding-left: 0 !important;
            background-color: #fff;
        }
    }

    .filters {


        & .ls-form {
            margin-bottom: 0;
            padding: 2px;
            max-height: 32px;

            & *,
            & {
                font-size: 14px;
            }

            & > div {

            }
        }
    }

    .center-form-container {
        height: auto;
        max-height: unset;

        & .center-form {
            padding: 15px;
        }

        & .form-wrapper {
            width: 100%;
            max-height: unset;
            overflow-y: visible;
            padding: 16px;

            & .ls-form {

                & label {
                    min-width: 210px;
                    max-width: 210px;
                }
            }
        }

        &.quote-details {

            & .form-wrapper {
                width: 100%;
                height: auto;
                padding: 0;
                border: 0;
                box-shadow: unset;

                & .additional-info {
                    margin-top: 0;
                }
            }

            & .right-panel {
                margin-top: 0;
                width: 100%;
            }
        }

        & .right-panel {
            border: 0;
            margin-top: 50px;
            height: auto;
            width: 100%;

            &-content {
                padding-bottom: 0;

                & .no-items {
                    padding: 0;
                }

                & .attachment-list {
                    padding: 24px;
                }
            }

            &__title {
                padding: 0;
                border: 0;
                position: relative;
                margin-bottom: 24px;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -20px;
                    left: -24px;
                    width: calc(100% + 48px);
                    height: 1px;
                    background-color: #E6E6E6;
                }
            }

            & .quotes-item {
                padding: 24px 0;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: -24px;
                    width: calc(100% + 48px);
                    height: 1px;
                    background-color: #E6E6E6;
                }

            }
        }

        &.contract-navigation {

            & .right-panel {
                width: 100%;
                margin-top: 0;
                border-top: 1px solid #DDDDDD;
                border-left: 1px solid #DDDDDD;

                & .ls-form {

                    & label {
                        min-width: 210px;
                        max-width: 210px;
                    }
                }
            }
        }
    }

    .popover,
    .modal-window {

        &.ld {
            width: 80% !important;
        }

        &.md {
            width: 450px;
        }

        & .ls-form-wrapper {

            & .ls-form {
                margin-bottom: 14px;

                & label {
                    margin-bottom: 6px;
                }

                &.ls-daterange {
                    min-width: unset;
                }
            }
        }
    }

    .popover {

        &.ld {

            & .filters-wrapper {
                justify-content: space-between;

                & .ls-form-wrapper {
                    width: calc(100% / 2 - 8px);
                    margin-right: 0;
                }
            }
        }
    }

    .contract-profit {

        & .profits-tile {
            flex-direction: column;

            & .contract-profit__summary-row {
                width: 100%;
            }
        }
    }

    .tile {

        & .tile-item {

            & .expenses-drop {
                width: calc(100% + 2px);
                top: -1px;
            }
        }
    }
}


@media only screen and (max-width: 480px) {



    .tile {
        margin-bottom: 24px;

        & .tile-item {
            width: 100%;
            padding: 12px;
            margin-bottom: 8px;

            &:nth-child(3),
            &:nth-child(4) {
                width: calc(100% / 2 - 4px);
            }

            & svg {
                min-width: 30px;
            }


                & .expenses-list {
                    margin-top: 65px;
                }

                & .expenses-item {
                    padding: 12px;
                }

                & .ls-textarea {
                    width: 80% !important;
                }

        }
    }

    .center-form-container {

        & .form-wrapper {
            padding: 15px 15px;

            & .ls-form {
                align-items: flex-start;
                flex-direction: column;
            }

            & .file-gallery {

                & .cell-3 {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 14px;
                }
            }
        }

        &.quote-details {

            & .chooses-content {

                & .cards {
                    width: calc(100% / 2 - 4px);
                    margin-bottom: 8px;

                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }
    }

    .popover,
    .modal {
        width: 80% !important;
    }

    .popover {
        & .popover-content {
            padding: 16px;
        }

        & .popover-buttons {
            padding: 16px 24px 0;
            margin: 24px -16px 0;
        }

        & .buttons {
            padding: 0;

            & > div {
                margin: 16px 0 0 0;
            }
        }
    }

    .contract-navigation {

        & .right-panel {

            & .ls-form {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    .float-buttons {
        flex-direction: row;

        & > div {
            margin-bottom: 0 !important;
            margin-right: 24px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .context-menu {

        &-list {
            bottom: 70px;
            right: -10px;
            min-width: auto;
        }

        &.contract {

            & .contract-navigation-menu {
                bottom: 70px;
                right: -10px;
                min-width: auto;
                width: calc(100vw - 30px);
            }

            & .contract-navigation {
                bottom: 79px;
                right: 0;
                width: 90vw;
                min-width: unset;
                max-height: 70vh;
            }
        }
    }

    .dashboard__summaries {

        & .dashboard__summary-widget {
            width: 100%;

            & .summary-widget__icon {
                margin-right: 5px;
            }

            & .summary-widget-info {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            & .summary-widget__label {
                margin-bottom: 0;
                margin-right: 20px;
            }
        }
    }

    .contract-history-item {
        padding: 16px 0;
    }
}

@media only screen and (max-height: 768px) {
    .popover {
        max-height: 90vh;
    }
}

@media only screen and (max-height: 480px) {
    .login {

        & .logo {
            margin-top: 0;
            margin-bottom: 34px;
        }

        & .certificate-img {
            display: none;
        }
    }
}
