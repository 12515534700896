.product-grid, .part-grid, .ticket-grid {
  background-color: #fff;

  & .categories {
    float: left;
    // height: calc(100% - #{$base-offset * 4.5});
    // margin-top: $base-offset * 4.5;
    // padding-top: $base-offset * 4.5;
  }
}

.part-grid, .inventory-grid {

  & .grid {
    box-shadow: -8px 0px 16px rgba(122, 127, 130, 0.08);
  }
}

.inventory-grid {
  & .categories {
    float: left;
  }
  .table-row {
    grid-template-columns: 300px 150px 150px 1fr;
    white-space: normal;

    &__item {
      word-break: break-all;
    }
  }
}
