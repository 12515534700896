.form-panel {
  position: relative;

  & form {
    &::before {
      content: '';
      display: block;
      clear: both;
    }
  }

  & .controls {
    font-size: 22px;
    line-height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0 (-$base-offset / 2);

    a {
      margin: 0 $base-offset / 2;
      transition: .4s;
      color: $dark;
      line-height: 24px;

      &:hover {
        transition: .4s;
        color: lighten($dark, 10);
        text-decoration: none;
      }

      &.red {
        &:hover {
          transition: .4s;
          color: $red;
        }
      }
    }
  }

  &-buttons {
    width: 100%;
    background-color: fade_out(#fff, .1);
    text-align: center;

    & .roundButton {
      display: inline-block;
      @include tertiaryBtn();
    }

    animation: buttonsAnimate ease-in-out .3s forwards;
  }
}

.right-panel.close > .form-panel-buttons {
  // animation: buttonsAnimateBack ease-in-out .3s forwards;
}

@keyframes buttonsAnimate {
  from {
    right: $base-offset - 312px;
  }
  to {
    right: $base-offset;
  }
}

@keyframes buttonsAnimateBack {
  from {
    right: $base-offset;
  }
  to {
    right: $base-offset - 312px;
  }
}
