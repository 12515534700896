.tickets {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, .1);
  height: 100%;

  @include clear();
  @include overflow(scroll);

  &-wrap {
    position: relative;
    width: 100%;
    min-height: 30vh;
    float: left;
  }

  & .grid-title {
    font-family: $font-book;
    font-size: 16px;
    margin: 0;
    padding: $base-offset * 1.5 $base-offset * 1.5 0;
  }

  & .grid-head {
    margin: 0;
  }

  & .grid-wrap {
    box-shadow: none;
  }

  & .other-info {
    display: flex;
  }
}

.assigned-badge {
  display: inline-block;
  margin-right: 12px;
}
