.showed {

    & > div {
        z-index: 12;
    }
}

.expenses-drop {
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 10;
    max-height: 76px;
    border-radius: 2px;
    border: 1px solid #E6E6E6;
    @include transition(300ms);
    background-color: #fff;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;

    .showed & {
        opacity: 1;
        visibility: visible;
        width: 538px;
        max-height: 1000px;
        box-shadow: 0 4px 16px rgba(54, 56, 57, 0.16);
        overflow: visible;
    }

    & .expenses-button {
        padding: $small-offset/2 0;

        & .tertiaryBtn {
            width: 50%;
            margin: 0 auto;
            display: block;
        }
    }
}

.expenses-list {
    position: absolute !important;
    width: 100%;
    left: 1px;
    margin-top: 72px;
    border-top: 1px solid #E6E6E6;
    cursor: auto;


    & .expenses-item {
        padding: $small-offset/2 $small-offset 15px;
        display: flex;
        flex-direction: column;
        position: relative;
        border-bottom: 1px solid #E6E6E6;


        & .remove,
        & .edit {
            position: absolute;
            right: 30px;
            bottom: 15px;
            @include transition(300ms);
            width: 20px;
            height: 20px;
            padding: 0;
            border: 0;
            background-color: transparent;

            & svg {
                height: 100%;
                width: 100%;
                fill: #9f9f9f;
                @include transition(300ms);
            }

            &:hover {

                & svg {
                    fill: $blue;
                }
            }

            &:focus {
                outline: 0;
            }
        }

        & .edit {
            right: 65px;

            & .plus {
                transform: rotate(45deg);
            }
        }

        & .cost {
            font-size: 22px;
            font-family: $font-semibold;
            color: #87B96A;
        }

        & .money {
            margin-right: 5px;
        }

        & .notes {
            word-wrap: break-word;
            margin-top: 5px;
            min-height: 24px;
            width: 85%;
        }

        & .title {
            margin-top: 0;
            font-family: $font-semibold;
        }

        &:last-child {
            border: 0;
        }

        & .ls-form {
            max-width: 100px;
            margin-bottom: 5px;

            &.ls-textarea {
                max-width: unset;
                width: 85%;
            }

            &.ls-select {
                width: 150%;
                max-width: 200px;
                cursor: pointer;

                & * {
                    font-family: $font-semibold;
                }

                & .css-1hb7zxy-IndicatorsContainer {
                    width: 30px;
                }

                & svg {
                    min-width: unset;
                }
            }

            &.ls-input {
                width: 70%;
            }

            & textarea {
                min-height: unset;
            }

            & .cost {
                color: #87B96A;
            }
        }

        &-heading {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

        }

        &__title {
            font-size: 18px;
            max-height: 100px;
        }

        &-date {
            color: rgba(#363839, .4);
            font-size: 12px;
            white-space: nowrap;
            margin-left: $small-offset;
            margin-right: auto;
        }

        &__cost {
            color: $blue;
            font-size: 26px;
            max-width: 100px;
            text-align: right;
            margin-right: auto;
        }
    }
}


.contract-navigation {

    & .tile {

        & .tile-item {
            position: static;

            & .expenses-drop {
                width: 100%;
            }
        }
    }
}
