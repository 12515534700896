.center-form-container.settings {

  & .form-wrapper {
    z-index: 10;
    width: 100%;
    padding: 0;
  }

  & .buttons {

    &-item {
      @include transition(300ms);

      &.active {
        background-color: rgba($blue, .50);
        color: #ffffff;
      }
    }
  }
}
