.form-modal {

  &-buttons {
    width: 100%;
    bottom: 0;
    left: 0;

    & button {
      width: 100%;
    }
  }
}
